<template>
	<div class="content">
		<!-- 头部 -->
		<div :class="{'header':true,'scroll white':isScrollTop,'white':true}">
			<div class="back" @click="$router.go(-1)"><img src="../../../assets/images/home/expert/back.png" alt=""></div>
			<div class="header-title">向TA提问</div>
		</div>
		<!-- <div class="ask pull-content list-section" v-if="ddEntrance == 'yes'">
			<div class="ask-top">
				<span><img :src="host + expertDetail.avatar" ></span>
				<p>请使用以下两种方式加入钉钉专属服务群，我 在您的专属服务群回答您的问题。</p>
			</div>
			<div class="ask-mode-item">
				<span :class="{'active':isActive}" @click="isActive=!isActive">专属公益服务群二维码</span>
				<span :class="{'active':!isActive}" @click="isActive=!isActive">专属公益服务群链接</span>
			</div>
			<div class="ask-mode-list">
				<div class="ask-mode-txt" v-show="isActive&&expertDetail.im_group_qr">
					<p>下载二维码并用钉钉扫一扫进入专属群</p>
					<p>点击识别二维码，进入专属群</p>
					<img :src="host + expertDetail.im_group_qr" @click="downImg()">
					<span @click="downImg()">点击下载二维码</span>
				</div>
				<div class="ask-mode-txt" v-show="!isActive">
					<p>专属群DingTalk链接<span>(复制此链接，在浏览器地址栏粘贴后打开钉钉， 自动进入专属群)</span></p>
					<div class="mode-links">{{expertDetail.im_group_link}}</div>
					<span @click="copyUrl()">点击复制此链接</span>
				</div>
			</div>
		</div> -->
		<div class="ask pull-content list-section" v-if="ddEntrance == 'yes'">
			<div class="ask-mode-txt" v-show="isActive&&expertDetail.im_group_qr">
				<p>单击以下二维码，长按识别后进入专属群</p>
				<img :src="host + expertDetail.im_group_qr" @click="downImg()">
			</div>
		</div>
		<div class="ask pull-content list-section" v-else>
			<div class="ask-mode-txt" v-show="isActive&&expertDetail.im_group_qr">
				<p>单击以下二维码，长按识别后进入专属群</p>
				<img :src="host + expertDetail.im_group_qr" v-image-preview>
			</div>
		</div>
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "expert-ask",
		data() {
			return {
				isScrollTop: false,
				isActive: true,
				id:'',
				expertDetail:[],
				ddEntrance:'',
				isToast: false,
				toastText: ''
			}
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			this.id = this.$route.query.id;
			this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			this.getDetailData();
			
		},
		mounted() {
			
		},
		computed: {
			
		},
		methods: {
			// 获取专家详情
			getDetailData() {
			  let that = this;
			  this.axios
			    .post(this.HOME + "/api/consumer/expert-info", {
			      id: that.id,
			    })
			    .then(function (res) {
			    	let list = res.data.data;
			    	let newArray = [];
			    	let array = {
			    	  id: list.id,
			    	  name: list.name,
			    	  avatar: list.avatar,
			    	  im_group_link: list.im_group_link,
			    	  im_group_qr: list.im_group_qr,
			    	}
			    	newArray = array;
			    	that.expertDetail = newArray;
			    });
			},
			
			
			// 复制链接
			copyUrl(){
				if(this.expertDetail.im_group_link != ''){
					let input = document.createElement("input");    // 直接构建input
					input.value = this.expertDetail.im_group_link;  // 设置内容
					document.body.appendChild(input);               // 添加临时实例
					input.select();                                 // 选择实例内容
					document.execCommand("Copy");                   // 执行复制
					document.body.removeChild(input);               // 删除临时实例
					this.toastText = '链接复制成功',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
				}
			},
			
			// 下载二维码事件
			downImg() {
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:'downImg',url:'https://ypzy.emingren.com' + this.expertDetail.im_group_qr});
			},
			
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop)
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		}
	}
</script>

<style scoped>
	.my-ewm{
		width: 86%;
		position: fixed;
		left: 7%;
		top: 20%;
		transform: rotateY(-50%);
		z-index: 999;
	}
	.my-ewm img{
		width: 100%;
	}
</style>