<template>
	<div class="content headlines">
		<div class="live-list">
			<div class="live-item" @click="goPage('/video01')">
				<img src="../../../assets/images/home/index/live-01.jpg" >
			</div>
			<div class="live-item" @click="goPage('/video02')">
				<img src="../../../assets/images/home/index/live-02.jpg" >
			</div>
			<div class="live-item" @click="goPage('/video03')">
				<img src="../../../assets/images/home/index/live-03.jpg" >
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "live",
		data() {
			return {
				
			}
		},
		beforeCreate() {
			
		},
		created() {

		},
		methods: {
			// 跳转页面
			goPage(path){
				this.$router.push(path);
			},
		}
	}
</script>

<style scoped>
	
</style>
