import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 


// Vue.config.productionTip = false
// Vue.prototype.host = 'https://ypzy.emingren.com'
Vue.prototype.host = 'https://sy.emingren.com'
Vue.prototype.HOME = '/api'

// 添加请求拦截器具
axios.interceptors.request.use(config => {
	if (config.url != "/api/api/user/login-by-Phone" && config.url != "/api/api/common/send-sms-captcha" &&config.url !='/api/api/user/has-phone-user') {
		if (!localStorage.token) {
			router.replace('/login');
			return config
		} else {
			config.headers.Authorization = "Bearer " + JSON.parse(localStorage.token)
		}
	}
	return config
})

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(res =>{
	console.log(res,'响应数据')
	//对响应数据做些事
	if(res.data.code == 0){
		if (res.config.headers.Authorization) {
			localStorage.token = JSON.stringify(res.config.headers.Authorization);
		}
	}else if(res.data.code == 6002 || res.data.code == 6001){
		router.push({
		  path:'/login',
		})
	}
	return res
})



Vue.use(VueAxios, axios)
new Vue({
	router,
	render: h => h(App)
}).$mount('#app')