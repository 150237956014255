<template>
	<div class="content">
		<!-- 头部 -->
		<div :class="{'header':true,'scroll white':isScrollTop,'white':true}">
			<div class="back" @click="$router.go(-1)"><img src="../../../assets/images/home/expert/back.png" alt="">
			</div>
			<div class="header-title">完善考生信息</div>
		</div>
		<div class="information pull-content list-section">
			<ul class="information-list">
				<li>
					<span><em>*</em>姓名</span>
					<p>
						<input type="text" value="" v-model="studentName" placeholder="请输入姓名">
					</p>
				</li>
				<li @click="openAddr()">
					<span><em>*</em>考籍</span>
					<p>
						<input type="text" readonly="readonly" :value="addrArray[addrIndex]" placeholder="请选择考生所在省份">
						<img src="../../../assets/images/home/expert/arrow-right.png">
					</p>
				</li>
				<li @click="openSex()">
					<span><em>*</em>性别</span>
					<p>
						<input type="text" readonly="readonly" :value="sexData[sexIndex].title" placeholder="请选择性别">
						<img src="../../../assets/images/home/expert/arrow-right.png">
					</p>
				</li>
				<li @click="openCategory()">
					<span><em>*</em>考生类型</span>
					<p>
						<input type="text" readonly="readonly" :value="categoryData[categoryIndex].title"
							placeholder="请选择考生类型">
						<img src="../../../assets/images/home/expert/arrow-right.png">
					</p>
				</li>
				<li @click="openDate()">
					<span><em>*</em>高考年份</span>
					<p>
						<input type="text" readonly="readonly" :value="dateArray[dateIndex]" placeholder="请选择高考年份">
						<img src="../../../assets/images/home/expert/arrow-right.png">
					</p>
				</li>
				<li>
					<span><em>*</em>选科组合</span>
					<div class="course-list">
						<span :class="{'checked':item.isActive}" v-for="(item,index) in subjectArray" :key="index"
							@click="onChange(index,item.id)">{{item.name}}</span>
					</div>
				</li>
				<li>
					<span>预估/模考成绩</span>
					<p>
						<input type="text" v-model="achievementExpect" placeholder="请填写预估/模考成绩（非必填）">
					</p>
				</li>
				<li>
					<span>预估/模考省内排名</span>
					<p>
						<input type="text" v-model="provinceRanking" placeholder="请填写预估/模考省内排名（非必填）">
					</p>
				</li>
			</ul>
		</div>
		<div class="information-btn" @click="setStudentInfo()">确认</div>
		<!-- 地址弹出框 -->
		<div class="addr-select-bg" v-show="isAddr" @click="isAddr=!isAddr"></div>
		<div class="addr-select-item" v-show="isAddr">
			<div class="addr-btns">
				<span>请选择城市</span>
				<span @click="isAddr=!isAddr">取消</span>
			</div>
			<ul>
				<li :class="{'checked':index == addrIndex}" @click="getAddrValue(index,item)"
					v-for="(item,index) in addrData" :key="index">
					{{item.name}}
					<img v-show="index == addrIndex" src="../../../assets/images/home/expert/yes.png">
				</li>
			</ul>
		</div>
		<!-- 性别弹出框 -->
		<div class="addr-select-bg" v-show="isSex" @click="isSex=!isSex"></div>
		<div class="addr-select-item" v-show="isSex">
			<div class="addr-btns">
				<span>请选择性别</span>
				<span @click="isSex=!isSex">取消</span>
			</div>
			<ul>
				<li :class="{'checked':index == sexIndex}" @click="getSexValue(index,item)"
					v-for="(item,index) in sexData" :key="index">{{item.title}}<img v-show="index==sexIndex"
						src="../../../assets/images/home/expert/yes.png"></li>
			</ul>
		</div>
		<!-- 日期弹出框 -->
		<div class="addr-select-bg" v-show="isDate" @click="isDate=!isDate"></div>
		<div class="addr-select-item" v-show="isDate">
			<div class="addr-btns">
				<span>请选择高考日期</span>
				<span @click="isDate=!isDate">取消</span>
			</div>
			<ul>
				<li :class="{'checked':index == dateIndex}" @click="getDateValue(index,item)"
					v-for="(item,index) in dateArray" :key="index">{{item}}<img v-show="index==dateIndex"
						src="../../../assets/images/home/expert/yes.png"></li>
			</ul>
		</div>
		<!-- 考生类别弹出框 -->
		<div class="addr-select-bg" v-show="isCategory" @click="isCategory=!isCategory"></div>
		<div class="addr-select-item" v-show="isCategory">
			<div class="addr-btns">
				<span>请选择考生类别</span>
				<span @click="isCategory=!isCategory">取消</span>
			</div>
			<ul>
				<li :class="{'checked':index == categoryIndex}" @click="getCategoryValue(index,item)"
					v-for="(item,index) in categoryData" :key="index">{{item.title}}<img v-show="index==categoryIndex"
						src="../../../assets/images/home/expert/yes.png"></li>
			</ul>
		</div>
		<!-- 咨询卡弹出框 -->
		<div class="addr-select-bg" v-show="isSeekCard" @click="isSeekCard=!isSeekCard"></div>
		<div class="seek-card" v-show="isSeekCard">
			<img @click="goPage('/expert')" src="../../../assets/images/user/seekcard.png">
			<img @click="closeEV()" src="../../../assets/images/user/close.png">
		</div>
		
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "information",
		data() {
			return {
				isScrollTop: false,
				
				isToast: false, //是否显示提示框
				toastText:'', //提示内容
				
				addrArray: [], //城市名称列表
				addrData: [], //考籍列表
				addrIndex: 0,
				isAddr: false, //城市列表是否显示
				addrValue: '', //考籍城市编号

				studentName: '', //考生姓名

				sexData: [{
						title: '男'
					},
					{
						title: '女'
					}
				],
				sexIndex: 0,
				isSex: false, //性别列表是否显示
				sexValue: '', //选择性别

				dateArray: [],
				dateIndex: 0,
				isDate: false, //时间列表是否显示
				dateValue: '', //选择时间

				categoryData: [{
						title: '普通高考生'
					},
					{
						title: '艺考生'
					},
					{
						title: '体考生'
					}
				],
				categoryIndex: 0,
				isCategory: false, //性别列表是否显示
				categoryValue: '', //选择性别

				checkedIndex: -1,
				isSeekCard: false,

				achievementExpect: '', //预估成绩
				provinceRanking: '', //预估排名

				// 学科
				subjectArray: [],
				subjectValue: [],
				subjectIndex: 0,

				numberArr: [],
				temporaryCode: [], //暂存编号
				limitArr: [], //多选一数组
				count: 0,
				userObj: '',
				lenNum: 0, //最少最多
				iscount: true,
				ispopu: false,
				consult_amount: 0,
				isfirst: 1,
			}
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			
			this.consult_amount = (JSON.parse(localStorage.getItem("centerUserData"))).consult_amount;
			
			console.log(this.isfirst,252525)
			this.userObj = JSON.parse(localStorage.getItem("centerUserData")).student;
			console.log(this.userObj,'用户信息')
			if(this.userObj){
				let list = this.userObj;
				this.studentName = list.student_name;//姓名
				this.addrValue = list.student_native;//考籍
				this.sexIndex = list.student_gender-1;//性别
				this.temporaryCode = list.student_subject;//选科组合
				//考生类型
				if(list.is_art==1){
					this.categoryIndex = 1;
				}else if(list.is_is_PE==1){
					this.categoryIndex = 2;
				}else{
					this.categoryIndex = 0;
				}
				this.achievementExpect = list.achievement_expect; //预估成绩
				this.provinceRanking = list.province_ranking //排名
			}
		},
		mounted() {
			this.getAddrData();
			this.getDate();
		},
		methods: {
			// 关闭
			closeEV(){
				this.isSeekCard=false;
				this.$router.push('/ucenter');
			},
			
			
			// 获取城市列表
			getAddrData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/area/index", {
						pcode: 86
					})
					.then(function(res) {
						let list = res.data.data;
						let newArray = [];
						let newObj = [];
						list.forEach(item => {
							let obj = {
								code: item.code,
								name: item.name,
								subject_white: item.subject_white,
								subject_radio_group: item.subject_radio_group,
								subject_radio_group_list: item.subject_radio_group_list,
								subject_max: item.subject_max
							}
							newArray.push(obj.name);
							newObj.push(obj);
						})
						that.addrArray = newArray;
						that.addrData = newObj;
						if(that.userObj){
							// 赋值考籍省份
							let addrIndex = that.addrArray.indexOf(that.userObj.student_native_str);
							that.addrIndex = addrIndex;
						}
						that.getSubjectArray();
					});
			},
			// 获取考试时间
			getDate() {
				const date = new Date();
				let year = Math.trunc(date.getFullYear());
				let end = year + 10;
				let newArray = [];
				for (let i = year; i <= end; i++) {
					newArray.push(i);
				}
				this.dateArray = newArray;
				this.dateValue = this.dateArray[this.dateIndex];
				if(this.userObj){
					// 赋值时间
					let yearIndex = this.dateArray.indexOf(this.userObj.student_exam_year);
					this.dateIndex = yearIndex;
				}
			},
			// 城市选择
			openAddr() {
				this.isAddr = !this.isAddr;
			},
			getAddrValue(index, item) {
				this.addrValue = item.code;
				this.addrIndex = index;
				this.isAddr = false;
				// 每次选择城市----清空待提交科目id数组
				this.temporaryCode = [];
				// 查询科目
				this.getSubjectArray();
			},
			// 选择性别
			openSex() {
				this.isSex = !this.isSex;
			},
			getSexValue(index, item) {
				this.sexValue = item.title;
				this.sexIndex = index;
				this.isSex = false;
			},
			// 选择时间
			openDate() {
				this.isDate = !this.isDate;
			},
			getDateValue(index, item) {
				this.dateValue = item;
				this.dateIndex = index;
				this.isDate = false;
			},
			//选择考生类别
			openCategory() {
				this.isCategory = !this.isCategory;
			},
			getCategoryValue(index, item) {
				this.categoryValue = item.title;
				this.categoryIndex = index;
				this.isCategory = false;
			},
			// 获取选科组合列表
			getSubjectArray() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/dictionary/subjects", {
						type: -1
					})
					.then(function(res) {
						let list = res.data.data;
						let newArray = [];
						let baiArr = that.addrData[that.addrIndex].subject_white.split(',');
						list.forEach(item => {
							baiArr.forEach(item1 => {
								if (item.id == item1) {
									let obj = {
										id: item.id,
										name: item.name,
										isActive: false
									}
							 	newArray.push(obj);
								}
							})

						})
						that.subjectArray = newArray;
						
						// 修改信息时，默认选中项
						if(that.userObj){
						  if(that.iscount){
							that.iscount = false;
							let stuJect = that.userObj.student_subject.split(',');
							let arrList = [];
							stuJect.forEach(item=>{
							  that.subjectArray.forEach(item1=>{
								if(item==item1.id){
								  item1.isActive = true;
								}
							  })
							  arrList.push(item*1);
							})
							that.temporaryCode = arrList;
						  }
						}
					});
			},
			// 选科组合选择事件
			onChange(index, subId) {
				let existSubid = this.temporaryCode.indexOf(subId);
				let existsubIndex = this.subjectArray.findIndex(item => item.id == subId);
				console.log(existSubid, existsubIndex, 299);
				if (this.addrData[this.addrIndex].subject_max != 1) {
					// 多选
					if (this.temporaryCode.length < this.addrData[this.addrIndex].subject_max) {
						// 没有超过限制选择的科目数量
						if (existSubid == -1) {
							if (this.addrData[this.addrIndex].subject_radio_group_list.length) {
								// 存在限制的科目id
								let limitSubid = this.addrData[this.addrIndex].subject_radio_group_list[0].findIndex(
									item => item == subId);
								let date = this.temporaryCode.findIndex(item => this.addrData[this.addrIndex]
									.subject_radio_group_list[0].indexOf(item) > -1)
								if (limitSubid != -1 && date != -1) {
									// 待提交科目数组id存在限制科目的id
									this.toastText = '选择科目冲突',
									this.isToast = true,
									setTimeout(()=> {
										this.isToast = false
									}, 1000)
								} else {
									// 待提交科目数组id不存在限制科目的id
									this.subjectArray[index].isActive = !this.subjectArray[index].isActive;
									this.setSubList(index, subId);
								}
							} else {
								// 不存在限制的科目的id
								this.subjectArray[index].isActive = !this.subjectArray[index].isActive;
								this.setSubList(index, subId);
							}
						} else {
							this.temporaryCode.splice(existSubid, 1);
							this.subjectArray[existsubIndex].isActive = false;
						}
					} else {
						// 超过了限制选择科目的数量
						if (existSubid != -1) {
							// 如果选中的科目id在待提交的数组里存在，则删除当前选中的科目
							this.temporaryCode.splice(existSubid, 1);
							this.subjectArray[existsubIndex].isActive = false;
						} else {
							this.toastText = '最多选择'+this.addrData[this.addrIndex].subject_max+'科',
							this.isToast = true,
							setTimeout(()=> {
								this.isToast = false
							}, 1000)
						}
					}
				} else {
					// 单选
					// 清空暂存编号
					this.temporaryCode = [];
					// 取消所有选中项
					this.subjectArray.forEach(item => {
						item.isActive = false;
					})
					// 调用设置科目列表事件
					this.setSubList(index, subId);
				}
			},
			// 设置科目列表事件
			setSubList(index, id) {
				// 设置选中项
				this.subjectArray[index].isActive = true;
				// 储存选中项的id到待提交数组里
				this.temporaryCode.push(id);
				console.log(this.temporaryCode, '数据');
			},

			// 编辑考生信息
			setStudentInfo() {
				let that = this;
				if(that.studentName == ''){
					this.toastText = '姓名不能为空',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
				}else if (that.temporaryCode.length != that.addrData[that.addrIndex].subject_max) {
					this.toastText = '必须选择'+this.addrData[this.addrIndex].subject_max+'科',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
				} else {
					let that = this;
					this.axios
						.post(this.HOME + "/api/user/edit-student-info", {
							student_name: that.studentName, //姓名
							student_gender: that.sexIndex == 0 ? 1 : 2, //性别
							student_native: that.addrData[that.addrIndex].code, //考籍（省级）编号
							student_native_str: that.addrData[that.addrIndex].name, //考籍（省级）名称
							student_subject: that.temporaryCode.join(','), //选科组合（选科ID，多个用英文逗号分割）
							student_exam_year: that.dateValue, //高考年份
							is_art: that.dateIndex == 1 ? 1 : 0, //是否是艺考生
							is_PE: that.dateIndex == 2 ? 1 : 0, //是否是体考生
							achievement_expect: that.achievementExpect, //预估或模考成绩
							province_ranking: that.provinceRanking, //预估/省内排名
							achievement_high: '', //高考成绩，-1表示未考（用户留空时默认传值为-1）
						})
						.then(function(res) {
							that.getUserData();
							if(that.consult_amount == 0){
								that.isSeekCard = true;
								that.isfirst = 0;
							}else{
								that.$router.push('/ucenter');
							}
							that.consult_amount = (JSON.parse(localStorage.getItem("centerUserData"))).consult_amount;
							console.log(res, 123)
						});
				}

			},
			getUserData() {
				this.axios.post(this.HOME + "/api/user/center-info").then(function(res) {
					console.log(res.data.data)
					localStorage.centerUserData = JSON.stringify(res.data.data);
				});
			},

			// 跳转
			goPage(path) {
				this.$router.push(path);
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop)
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		}
	}
</script>

<style scoped>

</style>
