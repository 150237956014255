<template>
	<div class="content main-content">
		<!-- 轮播图 -->
		<div class="banner">
			<img :src="host + bannerSrc">
		</div>
		<!-- 领取咨询机会 -->
		<div class="expert-receive" @click="goPage('/information')" v-if="consult_amount==0">
			<img src="../../../assets/images/home/expert/notice-01.png">
			<p>完善信息，立即领取免费咨询卡(1小时)</p>
			<span></span>
		</div>
		<div class="expert-receive expert-receive-01" v-if="consult_amount==1 && !isExpire">
			<img src="../../../assets/images/home/expert/notice-02.png">
			<p>您已领取一小时免费咨询权益，可向专家进行提问</p>
		</div>
		<!-- 导航 -->
		<!-- <div class="expert-nav-list section">
			<ul class="expert-nav-item">
				<li :class="{'active':current==index}" v-for="(item,index) in navData" :key="index" @click="changeNav(index)">{{item.title}}</li>
			</ul> -->
			<!-- 城市 -->
		<!-- 	<div class="addr-select">
				<div @click="openAddr()">{{addrArray[addrIndex]?addrArray[addrIndex]:'全部'}}</div>
			</div>
		</div> -->

		<!-- 咨询师列表 -->
		<div class="expert-list-all section">
			<ul class="expert-list">
				<li v-for="(item,index) in expertData" :key="index">
					<div class="expert-list-con" @click="toDetail(item.id)">
						<div class="expert-list-img"><img :src="host + item.avatar"></div>
						<div class="expert-list-txt">
							<div class="title">
								<h2>{{item.name}}</h2>
								<span v-if="item.tags_text">{{item.tags_text}}</span>
							</div>
							<div class="integral">
								<span>公益积分：{{item.score}}</span>
								<span>好评度：{{item.comment_score==0?item.comment_score:item.comment_score.toFixed(1)}}</span>
							</div>
							<div class="honor">
								<span v-for="(itemk,indexk) in item.keywords_list" :key="indexk">{{itemk}}</span>
							</div>
							<div class="slogan">{{item.slogan}}</div>
						</div>
					</div>
					<div class="expert-list-btns">
						<div :class="{'btn':true,'noservice':consult_amount==0}" @click="downImg(host + item.im_group_qr)" v-if="ddEntrance == 'yes'">向TA提问</div>
						<div :class="{'btn':true,'noservice':consult_amount==0}" v-else>
							向TA提问
							<span><img :src="host + item.im_group_qr" v-image-preview></span>
						</div>
					
						<!-- <div :class="{'btn':true,'noservice':consult_amount==0}" @click="toAsk(item.id)">向TA提问</div> -->
						<!-- <div :class="{'btn':true,'noservice':consult_amount==0}" @click="goToAsk(item.id)">向TA提问</div> -->
						<div class="btn" @click="toDetail(item.id)">查看详情</div>
					</div>
				</li>
			</ul>
		</div>

		<!-- 地址弹出框 -->
		<div class="addr-select-bg" v-show="isAddr" @click="isAddr=!isAddr"></div>
		<div class="addr-select-item" v-show="isAddr">
			<div class="addr-btns">
				<span>请选择城市</span>
				<span @click="isAddr=!isAddr">取消</span>
			</div>
			<ul>
				<li :class="{'checked':addrIndex == -1}" @click="getAddrValue(-1,'')">
					全部
					<img v-show="addrIndex == -1" src="../../../assets/images/home/expert/yes.png">
				</li>
				<li :class="{'checked':index == addrIndex}" @click="getAddrValue(index,item)" v-for="(item,index) in addrData" :key="index">
					{{item.name}}
					<img v-show="index == addrIndex" src="../../../assets/images/home/expert/yes.png">
				</li>
			</ul>
		</div>

		<!-- 领取咨询卡弹出框 -->
		<div class="my-confirm-bg" v-show="isConfirm" @click="isConfirm=!isConfirm"></div>
		<div class="my-confirm" v-show="isConfirm">
			<p>您还未领取免费咨询卡，请前往领取！</p>
			<div class="confirm-btns">
				<span @click="goPage('/information')">确认</span>
				<span @click="closeEV()">取消</span>
			</div>
		</div>
		<!-- 底部 -->
		<tabbar :current="2"></tabbar>
	</div>
</template>

<script>
	import tabbar from '../../../components/tabbar.vue';
	import "../../../../public/js/count.js";
	export default {
		name: "expert",
		components: {
			tabbar
		},
		data() {
			return {
				isExpire: false, //是否过有效期
				consult_amount: 0, //是否领取咨询机会
				current: 0,
				isConfirm: false,
				bannerSrc: '', //banner图地址
				navData: [{
					title: '所有专家'
				}, {
					title: '最近活跃'
				}, {
					title: '多元升学'
				}],
				expertData: [], //专家列表
				
				addrArray:[], //城市名称列表
				addrData: [], //城市列表
				addrIndex: -1,
				isAddr: false, //城市列表是否显示
				addrValue: '' , //选择城市编号
				ddEntrance:'', //钉钉进入
				isPluralism: 0, //是否为多元升学
			}
		},
		created() {
			this.consult_amount = (JSON.parse(localStorage.getItem("centerUserData"))).consult_amount;
			if(JSON.parse(localStorage.getItem("ddEntrance"))){
				this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			}
			// 获取当前时间
			let date2 = new Date();
			let dateNumber = '';
			dateNumber = dateNumber.concat(date2.getFullYear(),date2.getMonth()+1,date2.getDate());
			if(parseInt(dateNumber) > 2022614){
				this.isExpire = true;
			}
		},
		mounted() {
			this.getBanner();
			this.getAddrData();
		},
		methods: {
			// goToAsk(id){
			// 	let that = this;
			// 	this.axios
			// 	  .post(this.HOME + "/api/consumer/expert-info", {
			// 	    id: id,
			// 	  })
			// 	  .then(function (res) {
			// 		  console.log(res.data.data.im_group_link,'链接')
			// 		  window.open(res.data.data.im_group_link);
			// 		  dd.biz.util.openLink({
			// 				url: res.data.data.im_group_link,
			// 				onSuccess : function(res) {
			// 				// 调用成功时回调
			// 				console.log(res)
			// 			},
			// 				onFail : function(err) {
			// 				// 调用失败时回调
			// 				console.log(err)
			// 				}
			// 			});
			// 	  });
			// },
			// 导航切换
			changeNav(index) {
				switch (index) {
					case 0:
						this.current = 0;
						this.isPluralism = 0;
						break;
					case 1:
						this.current = 1;
						this.isPluralism = 0;
						break;
					case 2:
						this.current = 2;
						this.isPluralism = 1;
						break;
				}
			},

			// 获取banner
			getBanner() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/dictionary/get-slide-list", {
						position: "h5_expert_banner",
					})
					.then(function(res) {
						that.bannerSrc = res.data.data[0].src;
					});
			},


			// 获取专家列表
			getExpertData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/consumer/expert-list", {
						page: "1",
						size: "100",
						province_code: that.addrValue,
						is_pluralism: that.isPluralism
					})
					.then(function(res) {
						let list = res.data.data.list;
						let newArray = [];
						list.forEach(item=>{
						  let obj = {
							id: item.id,
							avatar: item.avatar,
							name: item.name,
							tags_text: item.tags_text,
							slogan: item.slogan,
							keywords_list: item.keywords_list,
							score: item.score,
							comment_score: item.comment_score,
							im_group_qr: item.im_group_qr,
						  }
						  newArray.push(obj);
						})
						that.expertData = newArray;
					});
			},


			// 获取城市列表
			getAddrData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/area/index", {
						pcode: 86
					})
					.then(function(res) {
						let list = res.data.data;
						let newArray = [];
						let newObj = [];
						list.forEach(item=>{
						  let obj = {
							code: item.code,
							name: item.name,
						  }
						  newArray.push(obj.name);
						  newObj.push(obj);
						})
						that.addrArray = newArray;
						that.addrData = newObj;
						that.getExpertData();
					});
			},


			// 打开城市弹窗
			openAddr() {
				this.isAddr = !this.isAddr;
			},
			// 城市选择
			getAddrValue(index, item) {
				this.addrValue = item.code;
				this.addrIndex = index;
				this.isAddr = false;
				this.getExpertData();
			},
			
			// 关闭提示框
			closeEV() {
				this.isConfirm = false;
			},


			// 向TA提问
			toAsk(id) {
				if (this.consult_amount >= 1) {
					this.$router.push({
						path: '/expert/ask',
						name: 'expert-ask',
						query: {
							id: id
						},
					});
				} else if (this.consult_amount == 0) {
					this.isConfirm = true;
				}
			},
			
			// 下载二维码事件
			downImg(url) {
				if (this.consult_amount >= 1) {
					// 网页向小程序 postMessage 消息
					dd.postMessage({name:'downImg',url: url});
				}else if (this.consult_amount == 0){
					this.isConfirm = true;
				}
			},
			
			
			// 跳转到详情页
			toDetail(id) {
				this.$router.push({
					path: '/expert/detail',
					name: 'expert-detail',
					query: {
						id: id
					},
				})
			},

			// 页面跳转
			goPage(path) {
				this.$router.push(path);
			},
		},
	}
</script>

<style scoped>
	.banner,
	.banner img {
		width: 100%;
	}
	.expert-list-btns span{
		display: block;
		width: 2.36rem;
		height: 0.56rem;
		border-radius: 0.28rem;
		overflow: hidden;
		position: relative;
		z-index: 1;
		margin-top: -0.56rem;
		opacity: 0;
	}
</style>
