<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		<div class="headlines-list">
			<p @click="goDetail('/text01')"><em></em><span>如何让孩子找到有幸福感的专业方向和职业目标？</span></p>
			<p @click="goDetail('/text02')"><em></em><span>生涯规划师的服务伦理，以及处理与客户价值判断不一致的方法探讨</span></p>
			<p @click="goDetail('/text03')"><em></em><span>升学规划师能力的“冰山结构”</span></p>
			<p @click="goDetail('/text04')"><em></em><span>双减政策，减“教”不减“学”；生涯规划的目标是点燃学生求学热情，真正体现教育情怀</span></p>
			<p @click="goDetail('/text05')"><em></em><span>强基计划、综合评价报名材料如何准备</span></p>
			<p @click="goDetail('/text06')"><em></em><span>数字化生涯规划师：获权威认证，服务钉钉千万考生与家长</span></p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "headlines",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {
			goDetail(path){
				this.$router.push(path);
			},
		}
	}
</script>

<style scoped>
	
</style>
