<template>
  <div class="content">
    <!-- 头部 -->
    <div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
      <div class="back" @click="$router.go(-1)">
        <img src="../../../assets/images/home/expert/back.png" alt="" />
      </div>
      <div class="header-title">专业定位测评</div>
    </div>
    <div class="entrance pull-content list-section">
      <div class="list-section">
        <h3>
          这里展示专业定位测评的文字内容，专业定位测评
          来帮你解读！高考志愿填报过程中，往往规划师老
          师，会让考生和家长做一个专业定位测
        </h3>
        <div class="entrance-tips">
          专业定位测评提示：你需要使用12-15分钟的时间，请
          用第一感觉作答，勿做太多的思考！
        </div>
        <div class="entrance-btns">
          <span @click="toEvaluation('/evaluation/question', 'student')">考生本人测评入口</span>
          <span @click="toEvaluation('/evaluation/question', 'parents')">家长/亲属测评入口</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "evaluation-entrance",
  data() {
    return {
      isScrollTop: false,
    };
  },
  created() {
    this.isScroll = true;
    window.addEventListener("scroll", this.eventScrollTop);
	
	if(this.$route.query.source){
		localStorage.source = JSON.stringify(this.$route.query.source);
	}
  },
  mounted() {
	  
  },
  computed: {},
  methods: {
    // 页面跳转
    toEvaluation(path, state) {
		if(!localStorage.token){
			this.$router.replace('/login');
			return false;
		}else{
			this.$router.push({
			  path: path,
			  query: {
			    state,
			  },
			});
		}
    },
    // 滚动改变样式
    eventScrollTop() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 5) {
        if (this.isScroll) {
          this.isScroll = false;
          this.isScrollTop = true;
        }
      } else {
        if (!this.isScroll) {
          this.isScroll = true;
          this.isScrollTop = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.eventScrollTop);
  },
  //keep-alive进入时触发
  activated() {
    this.isScroll = true;
    window.addEventListener("scroll", this.eventScrollTop);
  },
  //keep-alive离开时触发
  deactivated() {
    window.removeEventListener("scroll", this.eventScrollTop);
  },
};
</script>

<style scoped>
</style>