<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">综合评价</div>
		</div>
		<div class="headlines-txt plan-txt">
			<h2>综合评价面试培训课表</h2>
			<p>随着新高考改革的不断推进，尤其是在上海、浙江、山东、江苏等高考改革的先行省市，“综合评价招生”这一全新的高考录取方式，正扮演着越来越重要的角色，涵盖了各层次院校，既有985、211、双一流等名校，也有其他一本、二本院校，为不同层级的学生提供了更多的入学路径。</p>
			<img src="../../../assets/images/home/plan/201.png" alt="">
			<p>钉钉阿里云教育生涯规划师面试团队针对报考综合评价的高三学生，联合高校面试命题研究的相关教授以及多年从事自主选拔考试备考辅导的面试教师，结合多年高校面试培训经验，特别设计推出“综合评价面试培训课程”，还将提供多年面试真题、多渠道的命题趋势把握以及多类型综评面试模拟题，全面助力报考综合评价的高三学子！</p>
			<h2>综评面试培训内容</h2>
			<p>·高校基本面试礼仪讲解与示范，从仪容仪表发型着装等方面做好面试前期准备，占据形象制高点；</p>
			<p>·针对2020年综合评价深度剖析，拓展补充综合评价面试内容，多维度构建学生面试知识体系；</p>
			<p>·总结往年综合评价面试真题的基础上，以必考知识点、面试重难点与历年真题为载体，全面提升学生综合评价面试成绩；</p>
			<p>·梳理往年各大高校综评面试典型试题所涉及的知识体系和命题趋势，超强预测2020综合评价面试热点，使考生短期内对综评面试有清晰且深刻的把握，着重应试提分；</p>
			<p>·根据系统完整的综合评价模拟考试及教学体系，提供综合评价全真模拟题，并进行多轮实战模拟，帮助学生保持综合评价的应试感觉。</p>
			<p>·重点指导个人面谈和小组讨论面试技巧和答题方法，学习及提高综合水平，注重对学生逻辑思维、创新能力、甄辨能力、团队合作能力、抗压能力、以及价值观等综合素质的培养。</p>
			<h2>综评面试培训方式</h2>
			<p>线上直播+面授两大授课形式，考生可根据个人情况自由选择。</p>
			<p>VIP专属课程一对一，因名额有限，须提前预约。</p>
			<p>10人左右小班课，核心师资授课，保证高质量、高标准、高水平的面试课程内容。</p>
			<h2>综评面试培训师资</h2>
			<p><strong>张老师</strong></p>
			<p>北师大语言学应用硕士，优朗综合评价面试首席讲师，著名高校副教授。中国先秦史学会国学双语研究会理事，世界汉语大会国外教育部长随行翻译，华尔街某金融公司CEO访谈节目现场口译官。13年从业经验，9年专注高校面试，具备扎实的理论基础和实战经验。</p>
			
			<p><strong>宋老师</strong></p>
			<p>专职从事面试培训工作13年，中国面试辅导顶级专家，社会热点问题研究专家，浙大公务员面试特训模式的奠基者之一。教学体系博大精深，对综合应用能力考试和高校面试有深入研究。历年面试辅导成绩卓越，曾创下小组16人过15人的佳绩，至今无人打破！面试热点考题命中率高，被众多学员尊称为“押题大王”。</p>
			
			<p><strong>曹老师</strong></p>
			<p>毕业于誉满全球的顶尖学府——伦敦大学学院，曾在俄罗斯的高中担任中国文化传播大使，负责英文讲授经济、管理、文化等知识。曾任职于国内两大知名培训机构资深讲师，自带面试教研成果和理论体系，多次参加面试课程设计和研发，对面试技巧和真题解析尤其专长。</p>
			
			<p><strong>朱老师</strong></p>
			<p>理学硕士，十余年面试培训经验，曾任大型培训机构教学总监，开创省内赫赫有名的“封闭集训营”，进面率一度让报名人数爆棚，引领了各大集训营高端课程的开设。在高校领域也独创面试教学法，彻底解决了众多考生不敢说、无话说、说不好等疑难杂症，教学方法务实高效，感染力极强！</p>
			
			<p><strong>张老师</strong></p>
			<p>从事高校面试培训9年，《高校面试口语》教材参编。荣获中高级口语证书和口语段位赛第九段（最高段位）。有丰富的大学面试授课经验，教学风趣幽默，亲和力强，对面试思维能力提升有独到的方法。</p>
			
			<p><strong>李老师</strong></p>
			<p>外国语言学及英语语言学硕士，《高校面试口语》教材参编，6年高校教学经验，十多年丰富的面试口语教学经验。对面试类问题分析有独到的见解，面试思维应变力强，善于快速开拓学员的面试思维，短期内迅速提升学员综合能力。</p>

			<h2>单击以下二维码，弹出后识别即可联系规划师</h2>
			<div class="ewm" @click="downImg()" v-if="ddEntrance == 'yes'">
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" alt="">
			</div>
			<div class="ewm" v-else>
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" v-image-preview alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "textb",
		data() {
			return {
				ddEntrance:'', //钉钉进入
			}
		},
		beforeCreate() {
		},
		created() {
			if(JSON.parse(localStorage.getItem("ddEntrance"))){
				this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			}
		},
		methods: {
			// 下载二维码事件
			downImg() {
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:'downImg',url: 'http://aspevel.scdxtc.cn/img/dysxewm.jpg'});
			},
		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.plan-txt{
		padding-top: 1.3rem;
	}
	.headlines-txt>h2{
		font-size: .34rem;
		line-height: 1.5;
		text-align: center;
		margin-top: .3rem;
	}
	.plan-txt>h2:first-of-type{
		margin-top: 0;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
	.ewm{
		width: 100%;
	}
	.ewm img{
		width: 100%;
	}
</style>