<template>
	<div>
		<ul class="bottom-nav">
			<li :class="{home:true, active:homeStyle}" @click="goPage('/index')">
				<span><img src="../assets/images/home/main/index.png" alt=""></span>
				<p>首页</p>
			</li>
			<li :class="{expert:true, active:expertStyle}" @click="goPage('/expert')">
				<span><img src="../assets/images/home/main/expert.png" alt=""></span>
				<p>专家</p>
			</li>
			<li :class="{evaluation:true, active:evaluationStyle}" @click="goPage('/evaluation')">
				<span><img src="../assets/images/home/main/evaluation.png" alt=""></span>
				<p>测评</p>
			</li>
			<li :class="{my:true, active:myStyle}" @click="goPage('/ucenter')">
				<span><img src="../assets/images/home/main/my.png" alt=""></span>
				<p>我的</p>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "tabbar",
		props: ['current'],
		data() {
			return {
				homeStyle: true,
				expertStyle: false,
				evaluationStyle: false,
				myStyle: false,
				isLogin: false,
			}
		},
		created() {
			this.changeStyleNav(this.current);
		},
		beforeRouteUpdate(to, from, next) {
			this.changeStyleNav(this.current);
			next();
		},
		activated() {
			this.changeStyleNav(this.current);
		},
		methods: {
			// 判断当前高亮
			changeStyleNav(number) {
				switch (number) {
					case 1:
						this.homeStyle = true;
						this.expertStyle = false;
						this.evaluationStyle = false;
						this.myStyle = false;
						break;
					case 2:
						this.homeStyle = false;
						this.expertStyle = true;
						this.evaluationStyle = false;
						this.myStyle = false;
						break;
					case 3:
						this.homeStyle = false;
						this.expertStyle = false;
						this.evaluationStyle = true;
						this.myStyle = false;
						break;
					case 4:
						this.homeStyle = false;
						this.expertStyle = false;
						this.evaluationStyle = false;
						this.myStyle = true;
						break;
					default:
						this.homeStyle = true;
						this.expertStyle = false;
						this.evaluationStyle = false;
						this.myStyle = false;
						break;
				}
			},
			// 页面跳转
			goPage(url) {
				this.$router.replace(url);
			}
		}
	}
</script>

<style scoped>
	.bottom-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 1.3rem;
		background-color: #FFFFFF;
		box-shadow: 0 0 .2rem #efefef;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.bottom-nav li{
		width: 25%;
		font-size: .26rem;
		text-align: center;
		color: #333fc9;
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: grayscale(100%);
		 
	}
	.bottom-nav li img{
		width: .44rem;
		height: .44rem;
		margin: 0 auto .1rem;
	}
	.bottom-nav li.active{
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(0%);
		-ms-filter: grayscale(0%);
		-o-filter: grayscale(0%);
		filter: grayscale(0%); 
		transition: all .6s;
	}
</style>
