<template>
	<div class="content my-content">
		<!-- 头部 -->
		<div :class="{'header':true,'scroll white':isScrollTop,'white':true}">
			<div class="back"></div>
			<div class="header-title">我的</div>
		</div>
		<div class="consultant pull-content">
			<div class="consultant-photo">
				<div>
					<span><img :src="host + experObj.avatar"></span>
					<p>
						<span><em>姓名：</em>{{experObj.name}}</span>
						<span><em>职称：</em>{{experObj.tags_text}}</span>
						<span><em>电话：</em>{{experObj.phone}}</span>
					</p>
				</div>
				<p @click="editInformation()">修改基本资料</p>
			</div>
			<div class="consultant-info">
				<p><span>重点服务区域：</span>{{experObj.service_area_text}}</p>
				<p><span>区域1的服务价格：</span>{{experObj.price_area_a}}</p>
				<p><span>区域2的服务价格：</span>{{experObj.price_area_b}}</p>
				<p><span>区域3的服务价格：</span>{{experObj.price_area_c}}</p>
				<p><span>其他区域的服务价格：</span>{{experObj.price_area_other}}</p>
				<p><span>服务口号：</span>{{experObj.slogan}}</p>
			</div>
			<div class="case-administer">
				<h3>案例管理</h3>
				<span @click="addCase()">新增案例</span>
			</div>
			<ul class="consultant-case" v-if="expertCase.length>=1">
				<li>
					<span>封面图</span>
					<span>标题</span>
					<span>主案例</span>
					<span>操作</span>
				</li>
				<li v-for="(item,index) in expertCase" :key="index">
					<span><img :src="host + item.cover"></span>
					<span>
						<p>{{item.title}}</p>
					</span>
					<span>{{item.is_main==1?"是":"否"}}</span>
					<span>
						<ins>
							<em @click="editCase(index)">编辑</em>
							<em @click="deleteCase(item.id)">删除</em>
						</ins>
					</span>
				</li>
			</ul>
		</div>
		<div class="feedback-btn" @click="outLogin()">退出登录</div>
	</div>
</template>

<script>
	export default {
		name: "consultant",
		data() {
		 return {
				isScrollTop: false,
				id: '',
				page: 1, //页数
				size: 10, //条数
				is_main: -1, //主案件筛选
				allowLoad: true, //是否允许加载 默认：允许
				experObj: [], //咨询师详情
				expertCase: [], //咨询师案例
			}
		},
		created() {
			if(this.$route.query.token){
				let token = this.$route.query.token;
				localStorage.token = JSON.stringify(token);
			}
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		mounted() {
			this.checkExperInfo();
		},
		methods: {
			// 查询咨询师信息
			checkExperInfo() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/user/center-info", {})
					.then((res)=> {
						console.log(res, 123)
						that.experObj = res.data.data.expert
						that.id = that.experObj.id;
						console.log(that.id,1212121212)
						// 查询咨询师案例
						that.getExpertCase(that.id)
					});
			},

			// 获取案例列表
			getExpertCase(id) {
				let that = this;
				let anId = id;
				this.axios
					.post(this.HOME + "/api/expert/get-expert-case-list", {
						page: that.page,
						size: that.size,
						expert_id: anId,
						is_main: that.is_main
					})
					.then(function(res) {
						let list = res.data.data.list;
						let newArray = [];
						that.expertCase = [];
						list.forEach(item => {
							let obj = {
								id: item.id,
								cover: item.cover,
								title: item.title,
								result_remarks: item.result_remarks,
								location_remarks: item.location_remarks,
								other_remarks: item.other_remarks,
								account_remarks: item.account_remarks,
								is_main: item.is_main,
								student_name: item.student_name
						 }
							newArray.push(obj);
						})
						that.expertCase = that.expertCase.concat(newArray);
						console.log(that.expertCase,'案例列表数据')
					});
			},

			// 编辑案例
			editCase(index) {
				let editIndex = index;
				localStorage.anliObj = JSON.stringify(this.expertCase[editIndex]);
				this.$router.push('/case');
			},


			// 删除案例
			deleteCase(idk) {
				let that = this;
				let anId = idk;
				this.axios
					.post(this.HOME + "/api/expert/delete-expert-case", {
						ids: anId
					})
					.then(function(res) {
						console.log(res, 2525)
						that.getExpertCase(that.id);
						alert('删除成功');
					});
			},

			// 修改基本资料
			editInformation() {
				this.$router.push('/edit');
			},

			// 新增案例
			addCase() {
				localStorage.anliObj = JSON.stringify("");
				this.$router.push({
					path: '/case',
					name: 'case',
					query: {
						id: this.id
					},
				});
			},

			// 页面跳转
			goPage(path) {
				this.$router.push(path);
			},
			
			// 退出登录
			outLogin(){
				localStorage.clear();
				this.$router.push('/login');
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:"clearAll"});
			},
			
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop)
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		}
	}
</script>

<style scoped>
	.feedback-btn{
		width: 100%;
		border-bottom: .2rem solid #f1f4f7;
		border-top: .6rem solid #f1f4f7;
	}
</style>
