<template>
  <div class="content">
    <!-- 头部 -->
    <div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
      <div class="back" @click="$router.go(-1)">
        <img src="../../../assets/images/home/expert/back.png" alt="" />
      </div>
      <div class="header-title">{{title}}</div>
    </div>
    <div class="question pull-content list-section">
      <div class="question-item">
        <div class="number">
          <span>{{ num + 1 }}</span
          ><em>/</em>{{ lastquestions.total }}
        </div>
        <h3 v-if="state == 'student'">
          {{ lastquestions.list[num].question.content_student }}
        </h3>
        <h3 v-if="state == 'parents'">
          {{ lastquestions.list[num].question.content_parents }}
        </h3>
        <div class="question-btns">
          <span :class="{'active':cur==item.key}"
            v-for="(item, index) in optionsData"
            :key="index"
            @click="setQuestionEV(item.key)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "evaluation-question",
  data() {
    return {
      isScrollTop: false,
      lastquestions: [],
      stateBtn: true,
      num: 0,
      optionsData: [],
	  title: '',
	  state:'',
	  cur: ''
    };
  },
  created() {
    this.isScroll = true;
    window.addEventListener("scroll", this.eventScrollTop);
	this.title = (JSON.parse(localStorage.getItem("evaluationTitle")));
  },
  mounted() {
    this.getLastquestions();
	this.getQuestionAnswerOptions();
	this.state = this.$route.query.state;
  },
  computed: {},
  methods: {
    // 题目
    getLastquestions() {
      let that = this;
      this.axios
        .post(this.HOME + "/api/evaluation/get-last-questions", {
          role_type: this.$route.query.state,
        })
        .then(function (res) {
          that.lastquestions = res.data.data;
		  console.log(that.lastquestions,'试题数据')
        });
    },
	// 选项
    getQuestionAnswerOptions() {
      let that = this;
      this.axios
        .post(this.HOME + "/api/evaluation/question-answer-options")
        .then(function (res) {
          that.optionsData = res.data.data;
        });
    },

    // 滚动改变样式
    eventScrollTop() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 5) {
        if (this.isScroll) {
          this.isScroll = false;
          this.isScrollTop = true;
        }
      } else {
        if (!this.isScroll) {
          this.isScroll = true;
          this.isScrollTop = false;
        }
      }
    },
	
    setQuestionEV(key) {
      let that = this;
	  that.cur = key;
      if (this.stateBtn) {
        this.stateBtn = false;
        this.axios
          .post(this.HOME + "/api/evaluation/answer-question", {
            id: this.lastquestions.list[this.num].id,
            option_key: key,
          })
          .then(function () {
            that.num++;   
            that.stateBtn = true;
			that.cur = '';
            if (that.num >= 117) {
              console.log(that.num);
			  that.$router.push({
			  	path: "/report",
			  	query: {
			  		id: that.lastquestions.evaluation_id
			  	},
			  });
            }
          });
      }
    },
    destroyed() {
      window.removeEventListener("scroll", this.eventScrollTop);
    },
    //keep-alive进入时触发
    activated() {
      this.isScroll = true;
      window.addEventListener("scroll", this.eventScrollTop);
    },
    //keep-alive离开时触发
    deactivated() {
      window.removeEventListener("scroll", this.eventScrollTop);
    },
  },
};
</script>

<style scoped>
</style>