<template>
	<div class="start">
		<img src="../../../assets/images/home/start/start.jpg" alt="">
		<span>{{timeNumber}} 秒</span>
	</div>
</template>

<script>
	export default {
		name: "start",
		data() {
			return {
				timeNumber: 3,
				isInFirst: false
			}
		},
		beforeCreate() {
			this.isInFirst = JSON.parse(localStorage.getItem("isInFirst"));
			if(!this.isInFirst){
				setTimeout(() => {
					this.timeNumber = 2;
				}, 1000);
				setTimeout(() => {
					this.timeNumber = 1;
				}, 2000);
				setTimeout(() => {
					this.isInFirst = true;
					localStorage.isInFirst = JSON.stringify(this.isInFirst);
					this.$router.replace("/index");
				}, 3000);
			}else{
				this.$router.replace("/index");
			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.start {
		width: 100%;
		min-height: 100vh;
		position: relative;
		z-index: 999;
	}

	.start>img {
		width: 100%;
		min-height: 100vh;
	}

	.start>span {
		display: block;
		width: 1rem;
		line-height: .54rem;
		text-align: center;
		color: #FFFFFF;
		font-size: .28rem;
		border-radius: .27rem;
		background-color: #3f5ee0;
		position: fixed;
		left: .24rem;
		top: .5rem;
	}
</style>
