<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">如何让孩子找到有幸福感的专业方向和职业目标？</h1>
		<div class="headlines-txt">
			<p>教育部官网有一篇题为《高中阶段应尽早开展职业生涯规划》的文章，其中提出生涯规划教育要培养学生“幸福的技能”。</p>
			<img src="../../../assets/images/headlines/101.png" alt="">
			<p>生涯规划教育如何培养学生幸福的技能？又如何找到能给你孩子幸福的专业方向和职业目标？要回答这个问题，首先需要问你的孩子，他对于幸福是怎样理解和定义的。</p>
			<p>我们的孩子很可能和普罗大众一样（因为这个社会正全方位立体化的这样来引导大众）会认为幸福是某种来自外在的物质条件或精神奖赏得到了满足或实现。以职业为例，幸福的职业就是工资高、福利好、稳定性强、空闲时间多，还可以支持拥有更大的房子和开更好的车，从而让他和他的家人的生活比起别人更加舒适和安逸。而有助于导向这样的幸福的职业或者说专业，就是让人幸福的职业或者说是专业。如果您这样想，您自己认为这是现实么？我们可以心中默默祝福他会得到这样的机缘，但是我们要知道能力要与欲望相匹配才是要做的事情。我们要引导我们的孩子找到适合他自己的能力的专业或者职业，一个能力与欲望可以匹配的生存空间。</p>
			<img src="../../../assets/images/headlines/101.png" alt="">
			<p>咱们静思一下，这种幸福在“跑道尽头”或“山的那端”的观点是现代人或者说是现代性的谬误。那些抱有“工作是为了可以不工作”，“学习是为了不用再学习”态度的人，99%都实现不了他们的目标，到不了他们的终点，或者在实现了目标后，发现更远处的目标才是“终点”。然而最重要的是，他们在整个过程中，毫无幸福感可言。当然，如果这种不幸福只发生在学习和工作领域，毕竟还有另外16个小时，有可能在家庭或个人生活中获得幸福感；前提是，他没有把这种对于学习和工作的幸福观推广到家庭和个人生活中。</p>
			<p>小编是希望能有机会，给你的孩子提示一个新的思路、新的视角：让人幸福的专业和职业，是让人在学习和工作本身的过程中就能得到满足感、成就感的专业和职业；而不是那种需要通过学习和工作所获得的“报酬”（广义上的），或者说所交换来的“东西”才能带来满足感和成就感的专业和职业。</p>
			<img src="../../../assets/images/headlines/101.png" alt="">
			<p>如果咱们孩子所学专业或所从事的职业，既是他能力所擅长的，也是他身心所喜欢的，还是他的价值观体系所认同的；同时，我们提示他应该在每一个学习或工作的当下获得和体会幸福感。如果他能处在这样一种身心状态下，自然就可以愉悦地在他所从事的领域比80%的同伴表现得更优秀。咱们放下人生成就的大小姑且不论，如果能够这样，身心安康、幸福自在，这就是他人生每一刻的现实，而不只是远方的目标。这样我们可以帮孩子做到了诗和远方的结合。</p>
			<p>高考志愿填报，是生涯规划中的最为重要的关键节点之一。基于我在本文中所启示的职业目标与专业方向定位的思路和视角，需要对孩子进行完整的心理测评，通过有经验的数字化生涯规划师的专业指导来完成。如果你想了解更多，请关注有谱志愿（微信公众号：有谱志愿），关注钉钉上的数字化生涯规划师项目。</p>
			<p>最后，我们一起来领悟赵汀阳教授（中国人民大学）著作《论可能生活》中的一段话“产生幸福的行为只能是具有“自成目的性”的活动，这种活动就其本身而言是无代价的，因为这种活动本身就是该活动的成就。……即使这种行为在功利意义上得不到报偿，但它也已经以其本身产生出了生活的意义。”</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text01",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
