<template>
	<div class="content headlines">
		<div class="video">
			<video :src="playUrl" autoplay="autoplay" controls="controls">
				您的浏览器不支持 video 标签。
			</video>
		</div>
	</div>
</template>

<script>
	export default {
		name: "video01",
		data() {
			return {
				playUrl:''
			}
		},
		beforeCreate() {
		},
		created() {
			
		},
		mounted() {
			this.getViedo();
		},
		methods: {
			// 获取视频地址
			getViedo() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/consumer/get-ali-video", {
						video_key: 'A004_720',
					})
					.then(function(res) {
						console.log(res,'视频数据')
						that.playUrl = res.data.data.playUrl;
					});
			},
		}
	}
</script>

<style scoped>
	video{
		width: 100%;
	}
</style>
