<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">双减政策，减“教”不减“学”；生涯规划的目标是点燃学生求学热情，真正体现教育情怀</h1>
		<div class="headlines-txt">
			<p>生涯教育的目标是点燃学生主动求学的热情，真正体现教育者的教育情怀。了解数字化生涯规划师项目：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”。</p>
			<img src="../../../assets/images/headlines/401.png" alt="">
			<p>自2018年8月，国务院办公厅发布了《关于规范校外培训机构发展的意见》，着手规范校外培训；至2021年7月24日，中共中央办公厅、国务院办公厅发布《关于进一步减轻义务教育阶段学生作业负担和校外培训负担的意见》，“双减”政策重拳出击。</p>
			<p>从《意见》的标题看，所谓双减是减学生作业负担、减校外培训负担；前者来自于全日制学校校内，后者来自于家长的消费和教培机构服务的提供。</p>
			<p>“双减”可以理解为减教师和家长“教”的冲动；但“双减”不减的是学生主动求“学”的意志和行为。对于后半句，我想毋须论证。</p>
			<p>我们真正需要研究和讨论的是“没有教，能不能学？”的问题！！</p>
			<img src="../../../assets/images/headlines/402.png" alt="">
			<p>没有教，能不能学？这个问题从理论上来讲，答案是肯定的。在当今的资讯时代，获取信息、知识、方法、技能，并不依赖“教”的行为。不说传统的图书馆需要出门去找资料，就说互联网上足不出户就可以获得的学习材料都是数不胜数；而且这些材料还是动态实时更新的，足以支持几乎所有人的终身学习需求。但是，很可惜，在实践上，超过80%的学生（甚至包括同样多比例的成年人在内），是“无教不成学”的。</p>
			<p>笔者认为“无教不成学”正是教学的失败，也是教育需要努力去扭转的局面。如果教学的目的是“以教促学”；那么教育的目的就是“无教成学”。站在学习者的视角，前者是“被动学”，后者则是“主动学”。前者是双减的对象；后者不仅不是双减的对象，反而是双减要成就的目标。</p>
			<p>（如果双减政策的出发点，是通过减少家长和老师“教”的冲动和行为，而促成更多主动“学”的发生，这样的出发点无疑是有积极意义的。）</p>
			<p>问题是，如何放下“教”，成就“学”呢？如何放下“教学”，去真正开展“教育”呢？要怎么做呢？抓手在哪里？</p>
			<p>笔者认为促成学生主动求学的意志和行动，一个重要的抓手、发力点是生涯教育，特别是高中阶段的生涯规划教育。</p>
			<p>生涯教育的目标是点燃学生主动求学的热情，真正践行教育者的教育情怀。了解数字化生涯规划师项目：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”。</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text04",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
