<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">数字化生涯规划师：获权威认证，服务钉钉千万考生与家长</h1>
		<div class="headlines-txt">
			<p>志愿填报市场近来需求增长强劲，您若有志于在这一领域提供服务，寻找职业机会，请关注数字化生涯规划师认证项目，并抓住为钉钉上的考生和家长提供服务的机会。</p>
			<p>查看认证课程方法：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”</p>
			<img src="../../../assets/images/headlines/601.png" alt="">
			<p>在市场需求增长的同时，志愿填报从业者素质多被媒体和社会诟病。有鉴于此，阿里云教育引进北京师范大学课题项目成熟的能力体系与认证课程，为咨询师赋能，并提供面向钉钉考生与家长用户的咨询服务平台，其中也包含公益咨询服务的部分。</p>
			<p>近三年，我国每年高考考生人数规模均超过1000万人，录取率高于80%。我国的高等教育已于2019年进入普及化阶段，也即接受高等教育的人口占全部适龄人口的比例超过了50%。</p>
			<p>同时新高考改革已经推广到21个省，高考志愿填报的难度极大的增加了，因为填报对象已经从高校转向了高校的院系，也即招录单元从2800多所高校，扩展为10万多个院系；每个考生的志愿方案需要填报数十个至近百个专业和院校的组合；因而学生和家庭对于志愿填报的需求快速上升。</p>
			<img src="../../../assets/images/headlines/602.png" alt="">
			<p>在志愿填报需求快速增长的同时，社会对于志愿填报细分行业的从业者素质（咨询师的素质）表现出了严重的质疑。新华社等官媒在2021年5月发文提出质疑，教育部等部委随即提出监管要求。</p>
			<p>数字化生涯规划师是阿里云教育、北京师范大学、有谱志愿三方的协同共创项目。项目目标在于提升生涯规划，特别是志愿填报行业的从业者素质；并通过CtoC志愿咨询服务监理平台构建志愿填报需求方与生涯规划师之间的直接沟通平台，减少供需双方的交易成本。</p>
			<p>数字化生涯规划师认证课程，招募具备符合条件的两类候选人：一类是符合基础条件，但没有从事过志愿填报业务的人员；另一类是符合基础条件，且从事过志愿填报业务的人员（需要提供可验证的过往服务案例）。</p>
			<p>课程内容的主题是“志愿咨询业务背景及咨询师应有的能力结构”，有以下五个部分的内容：</p>
			<p>1、了解志愿填报业务的大背景</p>
			<p>2、理解与本认证课程相关的志愿填报业务规划</p>
			<p>3、理解生涯规划师（志愿填报）的能力结构</p>
			<p>4、理解课程结构、以及考核认证的要求</p>
			<p>5、能讲述志愿填报的基本逻辑</p>
			<p>进一步关注、了解认证课程和相关志愿咨询项目：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text06",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
