<template>
	<div class="content my-content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back" @click="$router.go(-1)">
				<img src="../../assets/images/home/expert/back.png" alt="" />
			</div>
			<div class="header-title">修改个人信息</div>
		</div>
		<div class="edit pull-content">
			<div class="edit-list">
				<div class="personal-item">
					<p>
						姓名：<span>{{ name }}</span>
					</p>
					<p>
						等级：<span>{{ tags_text }}</span>
					</p>
				</div>
				<!-- 形象照 -->
				<div class="personal-item">
					<p>形象照</p>
					<div class="personal-txt">
						<div class="personal-photo">
							<img v-if="myAvatar" :src="host + myAvatar" />
							<img v-else-if="handImg" :src="host + handImg" />
							<span v-else>上传图片的区域，照片必须使用固定的宽高比16:17，比如宽400像素，高425像素，或其它同等比例</span>
						</div>
						<div class="edit-btn">
							<input class="inputB" @change="imgIny($event)" type="file" />
							上传
						</div>
					</div>
				</div>
				<!-- 关键特征 -->
				<div class="personal-item">
					<p>关键特征1</p>
					<div class="left">
						<div class="personal-txt">
							<!-- 文本显示 -->
							<span v-if="noClick1" @click="noClick1=!noClick1">{{Characteristics[0]}}</span>
							<!-- 输入框 -->
							<input v-else v-model="Characteristics[0]" class="personal-input" />
						</div>
						<!-- 按钮事件 -->
						<div v-if="noClick1" @click="noClick1=!noClick1" class="edit-btn edit-btn-other">修改</div>
						<div v-else @click="CharacteristicsFun(1)" class="edit-btn">确认</div>
					</div>
				</div>
				<div class="personal-item">
					<p>关键特征2</p>
					<div class="left">
						<div class="personal-txt">
							<!-- 文本显示 -->
							<span v-if="noClick2" @click="noClick2=!noClick2">{{Characteristics[1]}}</span>
							<!-- 输入框 -->
							<input v-else v-model="Characteristics[1]" class="personal-input" />
						</div>
						<!-- 按钮事件 -->
						<div v-if="noClick2" @click="noClick2=!noClick2" class="edit-btn edit-btn-other">修改</div>
						<div v-else @click="CharacteristicsFun(2)" class="edit-btn">确认</div>
					</div>
				</div>
				<div class="personal-item">
					<p>关键特征3</p>
					<div class="left">
						<div class="personal-txt">
							<!-- 文本显示 -->
							<span v-if="noClick3" @click="noClick3=!noClick3">{{Characteristics[2]}}</span>
							<!-- 输入框 -->
							<input v-else v-model="Characteristics[2]" class="personal-input" />
						</div>
						<!-- 按钮事件 -->
						<div v-if="noClick3" @click="noClick3=!noClick3" class="edit-btn edit-btn-other">修改</div>
						<div v-else @click="CharacteristicsFun(3)" class="edit-btn">确认</div>
					</div>
				</div>
				<div class="personal-item">
					<p>关键特征4</p>
					<div class="left">
						<div class="personal-txt">
							<!-- 文本显示 -->
							<span v-if="noClick4" @click="noClick4=!noClick4">{{Characteristics[3]}}</span>
							<!-- 输入框 -->
							<input v-else v-model="Characteristics[3]" class="personal-input" />
						</div>
						<!-- 按钮事件 -->
						<div v-if="noClick4" @click="noClick4=!noClick4" class="edit-btn edit-btn-other">修改</div>
						<div v-else @click="CharacteristicsFun(4)" class="edit-btn">确认</div>
					</div>
				</div>
				<!-- 服务口号 -->
				<div class="personal-item">
					<p>服务口号</p>
					<div class="personal-txt">
						<div class="personal-top">
							<!-- 输入框 -->
							<textarea class="kh-input" v-model="sloganData" placeholder="请输入服务口号"></textarea>
						</div>
						<div @click="sloganDataFun()" class="edit-btn">确认</div>
					</div>
				</div>
				<!-- 个人简介 start -->
				<div class="personal-item">
					<p>个人简介</p>
					<div class="personal-txt">
						<div class="personal-top">
							<!-- 输入框 -->
							<textarea v-model="bodyData" placeholder="介绍您最闪光点，不要少于200字，不要多于300字"></textarea>
						</div>
						<div @click="bodyDataFun()" class="edit-btn">确认</div>
					</div>
				</div>
				<!-- 服务范围补充说明 -->
				<div class="personal-item">
					<p>服务范围补充说明</p>
					<div class="personal-txt">
						<div class="personal-top">
							<textarea v-model="explainData" placeholder="请填写补充说明"></textarea>
						</div>
						<div @click="explainDataFun()" class="edit-btn">确认</div>
					</div>
				</div>

				<!-- 请择3个重点服务省域 -->
				<div class="personal-item">
					<p>请择3个重点服务省域</p>
					<div class="personal-txt">
						<div class="city-item">
							<div>重点服务省域1：</div>
							<input type="text" v-model="addRess[0].name" readonly="readonly" @click="openCity1()" />
						</div>
						<div class="city-item">
							<div>重点服务省域2：</div>
							<input type="text" v-model="addRess[1].name" readonly="readonly" @click="openCity2()" />
						</div>
						<div class="city-item">
							<div>重点服务省域3：</div>
							<input type="text" v-model="addRess[2].name" readonly="readonly" @click="openCity3()" />
						</div>
					</div>
				</div>
				<!-- 服务价格（您是专家级咨询师可自由定价） -->
				<div class="personal-item bottom-box">
					<p>服务价格（您是专家级咨询师可自由定价）</p>
					<div class="personal-txt">
						<div class="price-item">
							<div>北京、上海</div>
							<input type="text" v-model="priceDataLstARR[0]" readonly="readonly" @click="openPrice1()" />
						</div>
						<div class="price-item">
							<div>天津、重庆、江苏、浙江、广东、福建</div>
							<input type="text" v-model="priceDataLstARR[1]" readonly="readonly" @click="openPrice2()" />
						</div>
						<div class="price-item">
							<div>辽宁、河北、山东、海南、湖北、湖南</div>
							<input type="text" v-model="priceDataLstARR[2]" readonly="readonly" @click="openPrice3()" />
						</div>
						<div class="price-item">
							<div>其他省域(老高考)</div>
							<input type="text" v-model="priceDataLstARR[3]" readonly="readonly" @click="openPrice4()" />
						</div>
						<div @click="priceDataLstFun()" class="edit-btn">确认</div>
					</div>
				</div>
			</div>
		</div>
		<div class="preview-btns">
			<span @click="navdetailLst()">预览列表页效果</span>
			<span @click="navdetail()">预览详情页效果</span>
		</div>
		<!-- 地址弹出框1 -->
		<div class="addr-select-bg" v-show="isCity1" @click="isCity1 = !isCity1"></div>
		<div class="addr-select-item" v-show="isCity1">
			<div class="addr-btns">
				<span>请选择城市</span>
				<span @click="isCity1 = !isCity1">取消</span>
			</div>
			<ul>
				<li :class="{ checked: addRess[0].name == item.name }" @click="getCityValue1(index, item)"
					v-for="(item, index) in provinceData" :key="index">
					{{ item.name
          }}<img v-show=" addRess[0].name == item.name " src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>

		<!-- 地址弹出框2 -->
		<div class="addr-select-bg" v-show="isCity2" @click="isCity2 = !isCity2"></div>
		<div class="addr-select-item" v-show="isCity2">
			<div class="addr-btns">
				<span>请选择城市</span>
				<span @click="isCity2 = !isCity2">取消</span>
			</div>
			<ul>
				<li :class="{ checked: addRess[1].name == item.name }" @click="getCityValue2(index, item)"
					v-for="(item, index) in provinceData" :key="index">
					{{ item.name
          }}<img v-show="addRess[1].name == item.name" src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>
		<!-- 地址弹出框3 -->
		<div class="addr-select-bg" v-show="isCity3" @click="isCity3 = !isCity3"></div>
		<div class="addr-select-item" v-show="isCity3">
			<div class="addr-btns">
				<span>请选择城市</span>
				<span @click="isCity3 = !isCity3">取消</span>
			</div>
			<ul>
				<li :class="{ checked: addRess[2].name == item.name  }" @click="getCityValue3(index, item)"
					v-for="(item, index) in provinceData" :key="index">
					{{ item.name
          }}<img v-show=" addRess[2].name == item.name " src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>

		<!-- 价格弹出框1 -->
		<div class="addr-select-bg" v-show="isPrice1" @click="isPrice1 = !isPrice1"></div>
		<div class="addr-select-item" v-show="isPrice1">
			<div class="addr-btns">
				<span>请选择价格</span>
				<span @click="isPrice1 = !isPrice1">取消</span>
			</div>
			<ul>
				<li :class="{ checked: priceDataLstARR[0] == item }" @click="getPriceValue1(index, item)"
					v-for="(item, index) in priceDataLst" :key="index">
					{{ item
          }}<img v-show="priceDataLstARR[0] == item" src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>
		<!-- 价格弹出框2 -->
		<div class="addr-select-bg" v-show="isPrice2" @click="isPrice2 = !isPrice2"></div>
		<div class="addr-select-item" v-show="isPrice2">
			<div class="addr-btns">
				<span>请选择价格</span>
				<span @click="isPrice2 = !isPrice2">取消</span>
			</div>
			<ul>
				<li :class="{ checked:priceDataLstARR[1] == item  }" @click="getPriceValue2(index, item)"
					v-for="(item, index) in priceDataLst" :key="index">
					{{ item
          }}<img v-show="priceDataLstARR[1] == item" src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>
		<!-- 价格弹出框3 -->
		<div class="addr-select-bg" v-show="isPrice3" @click="isPrice3 = !isPrice3"></div>
		<div class="addr-select-item" v-show="isPrice3">
			<div class="addr-btns">
				<span>请选择价格</span>
				<span @click="isPrice3 = !isPrice3">取消</span>
			</div>
			<ul>
				<li :class="{ checked: priceDataLstARR[2] == item  }" @click="getPriceValue3(index, item)"
					v-for="(item, index) in priceDataLst" :key="index">
					{{ item
          }}<img v-show=" priceDataLstARR[2] == item" src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>
		<!-- 价格弹出框4 -->
		<div class="addr-select-bg" v-show="isPrice4" @click="isPrice4 = !isPrice4"></div>
		<div class="addr-select-item" v-show="isPrice4">
			<div class="addr-btns">
				<span>请选择价格</span>
				<span @click="isPrice4 = !isPrice4">取消</span>
			</div>
			<ul>
				<li :class="{ checked: priceDataLstARR[3] == item  }" @click="getPriceValue4(index, item)"
					v-for="(item, index) in priceDataLst" :key="index">
					{{ item
          }}<img v-show=" priceDataLstARR[3] == item" src="../../assets/images/home/expert/yes.png" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "edit",
		data() {
			return {
				isScrollTop: false,
				
				noClick1:true, //能否编辑
				noClick2:true, //能否编辑
				noClick3:true, //能否编辑
				noClick4:true, //能否编辑
				
				tags_text: "",
				sloganData: "",
				Characteristics: [],
				bodyData: "",
				explainData: "",
				priceDataLstARR: [],
				editData: {
					avatar: "",
					keywords: "",
					slogan: "",
					summary: "",
					service_area_text: "",
					service_area_main: "",
					tel: "",
					ding_code: "",
					price_area_a: "",
					price_area_b: "",
					price_area_c: "",
					price_area_other: "",
				},

				isCity1: false, //城市列表是否显示
				provinceData: [],
				cityIndex1: 0,
				cityValue1: "", //选择城市编号

				isCity2: false, //城市列表是否显示
				cityIndex2: 0,
				cityValue2: "", //选择城市编号

				isCity3: false, //城市列表是否显示
				cityIndex3: 0,
				cityValue3: "", //选择城市编号
				addRess: [],
				// 价格区间
				priceDataLst: [],
				priceData: [],
				isPrice1: false, //价格列表是否显示
				priceIndex1: 0,
				priceValue1: "", //选择价格值

				isPrice2: false, //价格列表是否显示
				priceIndex2: 0,
				priceValue2: "", //选择价格值

				isPrice3: false, //价格列表是否显示
				priceIndex3: 0,
				priceValue3: "", //选择价格值

				isPrice4: false, //价格列表是否显示
				priceIndex4: 0,
				priceValue4: "", //选择价格值
				handImg: "", //头像

				myAvatar: "",
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		mounted() {
			this.checkExperInfo();
			this.getAddrData();
			this.getPricerData();
		},
		methods: {
			navdetail() {
				this.$router.push({
					path: "/edit/detail",
					query: {
						id: this.id,
					},
				});
			},
			navdetailLst() {
				this.$router.push({
					path: "/edit/list",
					query: {
						id: this.id,
					},
				});
			},
			priceDataLstFun() {
				this.editData.service_area_main = "";
				for (let index = 0; index < this.addRess.length; index++) {
					if (this.addRess[index].code) {
						this.editData.service_area_main += this.addRess[index].code + ",";
					}
				}

				console.log(this.editData.service_area_main)
				this.editData.price_area_a = this.priceDataLstARR[0];
				this.editData.price_area_b = this.priceDataLstARR[1];
				this.editData.price_area_c = this.priceDataLstARR[2];
				this.editData.price_area_other = this.priceDataLstARR[3];
				this.editExpertInfo();
			},
			explainDataFun() {
				this.editData.service_area_text = this.explainData;
				this.editExpertInfo();
			},
			bodyDataFun() {
				this.editData.summary = this.bodyData;
				this.editExpertInfo();
			},
			sloganDataFun() {
				this.editData.slogan = this.sloganData;
				this.noClick5 = !this.noClick5;
				this.editExpertInfo();
			},
			CharacteristicsFun(editIdex) {
				console.log(this.Characteristics);
				this.editData.keywords = "";
				for (let index = 0; index < this.Characteristics.length; index++) {
					this.editData.keywords += this.Characteristics[index] + ",";
				}
				this.editExpertInfo();
				
				console.log(this.editData.keywords, "123");
				if(editIdex == 1){
					this.noClick1 = !this.noClick1
				}else if(editIdex == 2){
					this.noClick2 = !this.noClick2
				}
				else if(editIdex == 3){
					this.noClick3 = !this.noClick3
				}
				else if(editIdex == 4){
					this.noClick4 = !this.noClick4
				}
			},
			ModifyData() {
				this.axios
					.post(this.HOME + "/api/expert/edit-expert-info")
					.then(function() {})
					.catch(function(error) {
						console.log(error);
					});
			},
			getPricerData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/expert/service-price-list", {
						pcode: 86,
					})
					.then(function(res) {
						console.log(res.data.data, "------");
						that.priceDataLst = res.data.data;
					});
			},
			getAddrData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/area/index", {
						pcode: 86,
					})
					.then(function(res) {
						console.log(res);
						that.provinceData = res.data.data;
						console.log(that.provinceData);
						let list = res.data.data;
						let newArray = [];
						let newObj = [];
						list.forEach((item) => {
							let obj = {
								code: item.code,
								name: item.name,
							};
							newArray.push(obj.name);
							newObj.push(obj);
						});
					});
			},

			// 上传形象照
			imgIny(e) {
				let that = this;
				const files = e.target.files;
				console.log(files[0]);
				console.log({
					image: files[0],
				});
				var fromdata = new FormData();
				fromdata.append("image", files[0]);
				this.axios
					.post(this.HOME + "/api/file/upload/image", fromdata, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(function(res) {
						that.editData.avatar = res.data.data.src;
						that.myAvatar = res.data.data.src;
						that.editExpertInfo();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 城市选择
			openCity1() {
				this.isCity1 = !this.isCity1;
			},
			getCityValue1(index, item) {
				console.log(item);
				this.addRess[0].code = item.code;
				this.addRess[0].name = item.name;
				this.cityValue1 = item.title;
				this.cityIndex1 = index;
				this.isCity1 = false;
			},
			openCity2() {
				console.log(this.provinceData);
				this.isCity2 = !this.isCity2;
			},
			getCityValue2(index, item) {
				this.addRess[1].code = item.code;
				this.addRess[1].name = item.name;
				this.cityValue2 = item.title;
				this.cityIndex2 = index;
				this.isCity2 = false;
			},
			openCity3() {
				this.isCity3 = !this.isCity3;
			},
			getCityValue3(index, item) {
				this.addRess[2].code = item.code;
				this.addRess[2].name = item.name;
				this.cityValue3 = item.title;
				this.cityIndex3 = index;
				this.isCity3 = false;
			},
			// 价格选择
			openPrice1() {
				this.isPrice1 = !this.isPrice1;
			},
			getPriceValue1(index, item) {
				console.log(this.priceDataLst);
				this.priceDataLstARR[0] = item;
				this.priceValue1 = item.title;
				this.priceIndex1 = index;
				this.isPrice1 = false;
			},
			openPrice2() {
				this.isPrice2 = !this.isPrice2;
			},
			getPriceValue2(index, item) {
				this.priceDataLstARR[1] = item;
				this.priceValue2 = item.title;
				this.priceIndex2 = index;
				this.isPrice2 = false;
			},
			openPrice3() {
				this.isPrice3 = !this.isPrice3;
			},
			getPriceValue3(index, item) {
				this.priceDataLstARR[2] = item;
				this.priceValue3 = item.title;
				this.priceIndex3 = index;
				this.isPrice3 = false;
			},
			openPrice4() {
				this.isPrice4 = !this.isPrice4;
			},
			getPriceValue4(index, item) {
				this.priceDataLstARR[3] = item;
				console.log(this.priceDataLst);
				this.priceValue4 = item.title;
				this.priceIndex4 = index;
				this.isPrice4 = false;
			},

			// 查询咨询师信息
			checkExperInfo() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/user/center-info", {})
					.then(function(res) {
						console.log(res.data.data.expert);
						let newArray = res.data.data.expert;
						that.id = newArray.id;

						(that.editData = {
							avatar: newArray.avatar,
							keywords: newArray.keywords,
							slogan: newArray.slogan,
							summary: newArray.summary,
							service_area_text: newArray.service_area_text,
							service_area_main: newArray.service_area_main,
							tel: newArray.tel,
							ding_code: newArray.ding_code,
							price_area_a: newArray.price_area_a,
							price_area_b: newArray.price_area_b,
							price_area_c: newArray.price_area_c,
							price_area_other: newArray.price_area_other,
						}),
						(that.handImg = newArray.avatar);
						that.name = newArray.name;
						that.tags_text = newArray.tags_text;
						that.sloganData = newArray.slogan;
						that.bodyData = newArray.summary;
						that.explainData = newArray.service_area_text;
						that.Characteristics = res.data.data.expert.keywords.split(",");
						that.addressData = res.data.data.expert.service_area_main.split(",");

						that.priceDataLstARR[0] = newArray.price_area_a,
							that.priceDataLstARR[1] = newArray.price_area_b,
							that.priceDataLstARR[2] = newArray.price_area_c,
							that.priceDataLstARR[3] = newArray.price_area_other,
							that.addRess = res.data.data.expert.service_area_main_list;

						if (that.addRess.length < 3) {
							for (let index = 0; index < 3; index++) {
								that.addRess.push({
									code: "",
									name: "未填写",
								});
							}
						}
						console.log(that.addRess);
					});
			},

			// 页面跳转
			goPage(path) {
				this.$router.push(path);
			},
			
			// 修改咨询师信息
			editExpertInfo() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/expert/edit-expert-info", this.editData)
					.then(function(res) {
						console.log(res);
						that.checkExperInfo();
						this.$router.push('/consultant');
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
</style>
