<template>
	<div class="content my-content main-content">
		<!-- 轮播图 -->
		<div class="banner my-banner">
			<img src="../../../assets/images/user/banner.jpg" alt="" />
			<div class="my-photo" @click="goPage('/info')">
				<span>
					<img v-if="userData.avatar" :src="userData.avatar.indexOf(0,4)=='http'?userData.avatar:host + userData.avatar" />
					<img v-else src="../../../assets/images/user/photo.png" />
				</span>
				<p v-if="userData.nick_name">{{ userData.nick_name }}</p>
				<p v-else>未填写个人信息</p>
			</div>
		</div>
		<!-- 免费咨询卡 -->
		<div class="user-receive" @click="toPage()" v-if="!isExpire">
			<img src="../../../assets/images/user/card.png" />
			<div class="receive-txt">
				<span class="use-btn" v-if="consult_amount == 1">立即使用</span>
				<span class="receive-btn" v-if="consult_amount == 0">立即领取</span>
				<p v-if="consult_amount == 1 && startTime">有效期：{{startTime}}-2022.6.14</p>
			</div>
		</div>
		<div class="line" v-if="consult_amount == 1"></div>
		<ul class="student-information">
			<li>
				<span><img src="../../../assets/images/user/icon-phone.png" /></span>
				<p>
					手机号<em>{{ userData.mobile }}</em>
				</p>
			</li>
			<li @click="goPage('/information')">
				<span><img src="../../../assets/images/user/icon-student.png" /></span>
				<p>考生信息</p>
				<img src="../../../assets/images/home/expert/arrow-right.png" />
			</li>
		</ul>
		<div class="feedback" @click="goPage('/feedback')">
			<span><img src="../../../assets/images/user/icon-feedback.png" /></span>
			<p>意见和反馈</p>
			<img src="../../../assets/images/home/expert/arrow-right.png" />
		</div>
		<div :class="{ 'my-report': true, 'open': isOpen }" @click="isOpen = !isOpen">
			<span><img src="../../../assets/images/user/icon-report.png" alt="" /></span>
			<p>我的报告</p>
			<img src="../../../assets/images/home/expert/arrow-right.png" alt="" />
		</div>
		<ul :class="{'report-list': true, 'open': isOpen}">
			<li v-for="(item,index) in reportData" :key="index" @click="goReport(item.id)">
				<p>{{item.title}}</p>
				<span>{{item.done_at}}</span>
			</li>
		</ul>
		<div class="feedback-btn" @click="outEV()">退出登录</div>
		<!-- 确认框 -->
		<div class="my-confirm-bg" v-show="isConfirm" @click="isConfirm=!isConfirm"></div>
		<div class="my-confirm" v-show="isConfirm">
			<p>确定退出当前账号吗？</p>
			<div class="confirm-btns">
				<span @click="outLogin()">确认</span>
				<span @click="closeEV()">取消</span>
			</div>
		</div>
		<tabbar :current="4"></tabbar>
	</div>
</template>

<script>
	import tabbar from "../../../components/tabbar.vue";
	import "../../../../public/js/count.js";
	export default {
		name: "ucenter",
		components: {
			tabbar,
		},
		data() {
			return {
				centerUserData: {
					mobile: "",
				},
				isConfirm: false,
				isExpire: false, //是否失效
				consult_amount: 0,
				reportData: [],
				isUse: 0, //是否使用
				isOpen: false,
				userData: {
					avatar: "",
					consult_amount: 0,
					create_time: "",
					ding_code: "",
					expert_id: 0,
					gender: 0,
					id: 31,
					im_uid: "",
					lat: "",
					lng: "",
					login_time: "",
					mobile: "",
					nick_name: "",
					open_id: "",
					real_name: "",
					tags: "",
					tel: "",
					type: 0,
					unionid: "",
					update_time: "",
					user_code: "",
				},
				student_first_complete:'' ,//领取咨询卡时间
				startTime: '', //有效期开始时间
				endTime: '', //有效期结束
			};
		},
		created() {
			this.getUserData();
			this.getDate();
			this.getevaluationList()
			this.consult_amount = (JSON.parse(localStorage.getItem("centerUserData"))).consult_amount;
		},
		mounted() {
			
		},
		methods: {
			// 获取报告列表
			getevaluationList() {
				let that = this
				this.axios.post(this.HOME + "/api/evaluation/evaluation-list", {
					page: "1",
					size: "100",
					status: "done"
				}).then(function(res) {
					that.reportData = res.data.data.list;
					if(that.reportData.length>0){
						that.isOpen = true
					}
					console.log(that.reportData,555)
				});
			},
			
			// 判断跳转方式
			toPage() {
				if (this.consult_amount == 1) {
					this.$router.push("/expert");
				} else if (this.consult_amount == 0) {
					this.$router.push("/information");
				} else {
					return false;
				}
			},

			// 页面跳转
			goPage(path) {
				this.$router.push(path)
			},

			// 查看报告
			goReport(id) {
				this.$router.push({
					path: "/report",
					query: {
						id: id
					},
				});
			},

			// 获取用户信息
			getUserData() {
				let that = this;
				this.axios.post(this.HOME + "/api/user/center-info").then(function(res) {
					that.userData = res.data.data;
					localStorage.centerUserData = JSON.stringify(res.data.data);
				});
			},
			
			// 获取有效时期
			getDate() {
				let starTime = (JSON.parse(localStorage.getItem("centerUserData"))).student.student_first_complete;
				console.log(starTime,'111111')
				let date = new Date(starTime.replace(/-/g, '/'));
				this.startTime = date.getFullYear()+"."+(date.getMonth()+1)+"."+date.getDate(); //有效期开始时间
				console.log(this.startTime,'2222222')
				let date1 = new Date(starTime.replace(/-/g, '/'));
				date1.setDate(date.getDate()+7);
				this.endTime = date1.getFullYear()+"."+(date1.getMonth()+1)+"."+date1.getDate(); //有效期结束时间
				
				// 获取当前时间
				let date2 = new Date();
				let dateNumber = '';
				dateNumber = dateNumber.concat(date2.getFullYear(),date2.getMonth()+1,date2.getDate());
				if(parseInt(dateNumber) > 2022614){
					this.isExpire = true;
				}
				// if(date1.setDate(date.getDate()+7) <= date2.setDate(date.getDate())){
				// 	this.isExpire = true;
				// }
			},
			
			// 退出登录
			outEV(){
				this.isConfirm = true
			},
			closeEV(){
				this.isConfirm = false
			},
			outLogin(){
				localStorage.clear();
				this.$router.push('/login');
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:"clearAll"});
			},
			
		},
	};
</script>

<style scoped>
	.banner,
	.banner img {
		width: 100%;
	}
</style>
