<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">生涯规划师的服务伦理，以及处理与客户价值判断不一致的方法探讨</h1>
		<div class="headlines-txt">
			<p>生涯规划师，因其以专业咨询服务者的身份，深度参与到服务对象的学业、升学、职业选择等对人生有重大影响的规划与决策过程中，因而其所从事的工作，与教师、医生一样，有着极高的伦理和职业道德要求。</p>
			<p>数字化生涯规划师的认证流程中，就对从业者的职业伦理有明确的要求。数字化生涯规划师的职业伦理要求主要体现在以下四个方面：一、具有责任心，愿意助人为乐，能带着关爱、热忱开展咨询工作；二、遵守忠诚、正义的原则，受人之托，忠人之事，慎重考虑自己的服务对于被服务者的影响；三、要尊重服务对象的多样性和差异性，站在被服务者的（而非咨询师自己的）视角审视自己所提供的咨询服务的价值；四、妥善管理客户信息，避免非法或在未经授权的情况下泄露、造成用户信息损失。</p>
			<img src="../../../assets/images/headlines/201.png" alt="">
			<p>虽然有上述关于职业伦理的直接要求，然而，对于一个生涯规划师来说，如果专业知识和职业能力不达标的话，也必然会导致职业伦理上的缺陷，这一点也是不言自明的。</p>
			<p>数字化生涯规划师所面临的现实的咨询服务场景非常复杂，以志愿填报服务为例，志愿填报服务中的客户构成通常除了考生以外，还有对考生具有重要影响力的家长。笔者接下来要和大家交流的话题是，志愿填报服务场景中，如何处理与客户之间价值判断上的冲突。</p>
			<p>在志愿填服务中，生涯规划师的专业服务的价值体现为：<strong>作出有利于考生长期人生发展的高等教育升学规划</strong>，并且体现在志愿填报方案，或其它多元升学通道的执行方案中。然而，在现实的服务场境中，对于哪些选择是有利于考生长期人生发展的，往往并不容易与考生或其父母达成一致。这时候，生涯规划师同时面临着专业能力、伦理道德、沟通技巧等的多重考验。</p>
			<img src="../../../assets/images/headlines/202.png" alt="">
			<p>笔者团队曾在服务中遇到一位家长，他要求咨询师给孩子找一个容易就业的、且就业稳定的专业。当咨询师问家长他认为哪些职业是就业用稳定的，他回答说银行、税务、公安……。在这个咨询案例中，而来自县城普通市民家庭的这位考生，取得了位于所有考生前5%位次的高考成绩，可以妥妥地进入211院校，甚至可以考虑985院校，学他喜欢又擅长的土木或计算机类专业。我们的咨询师耐心地向这位家长解释了以下几层意思：</p>
			<p>一、你所认为的，目前看起来是稳定、容易就业、薪资也还不错的银行、税务、公安等类的职务，且不说入职这些职务，大多还需要经历一道竞争更激励的公务员考试；更重要的是，这些职位在三五年后未必还会象现在这样；人类的职业正进入一个加速变动的时代，2015年中华人民共和国职业分类大典，相比于1999年的版本，就去除了60%的职位，同时新增了23%的职位。</p>
			<p>二、终生学习的意愿、能力和行动力，是生涯竞争力最关键的内生性的要素。专业定位是帮助考生寻找他擅长的、喜欢的、认为有价值的三个维度的交叉领域，目标在于让考生在高等教育及以后的人生阶段尽可能保持终生学习的意愿、能力和行动力。所以专业定位要符合孩子的天性，其意义至关重要。</p>
			<p>三、一个在同侪中排名前5%的优秀青年，选一所211院校，学一个自己喜欢和擅长的专业，怎么还需要担心就业压力呢，在兴趣、意愿的驱使下他会保持终身学习的态度，他应该让别人感到就业竞争压力才对啊！</p>
			<p>这个案例中价值冲突的化解还是比较顺利的，毕竟考生站在咨询师这一边，而家长也很快就尊重了考生的意见。</p>
			<p>我们知道有事实判断，有价值判断。事实判断比较容易达成一致，当然前提是各方的数据和信息是同步的；价值判断更难达成一致，是因为各有各的价值偏好，更多还出于价值观等底层逻辑。</p>
			<p>作为数字化生涯规划师，在面临与客户的价值判断冲突时，首先需要以事实判断为基础，通过明确的事实数据和客观信息的呈现，努力争取在事实判断上和客户达成一致；比如上一个案例中关于社会职位变动的数据。而数字化生涯规划师中的数字化，也喻示具有更强的数据查找和信息检索能力。其次，在价值判断上，需要努力展示自己所坚持的科学的认知模型和思维框架，比如上述案例中，对于生涯竞争力来源的陈述。最后，是价值观沟通，努力实现双方的价值观握手，现实的咨询业务中如果出现价值观分裂（或部分分裂），我们会要求咨询师将案例上报，采用专家会诊等方法来解决冲突。</p>
			<img src="../../../assets/images/headlines/203.png" alt="">
			<p>面对价值判断不一致的局面，生涯规划师不应有的态度及相应的处理方案有两种：一种是固执己见，要求客户无条件服从自己的想法和方案；一种是简单地迁就客户，结束分歧，以求尽早结案。</p>
			<p>总结一下，数字化生涯规划师是专业的参谋，参与的是影响服务对象一生的重大决策；因而，除了基础知识和专业能力需要过硬外，职业伦理和职业道德水平也是一个非常重要的认证维度。在现实的咨询案例中，出现与客户的价值判断不一致的局面时，最容易体现出规划师有没有，以及能不能坚持应有的职业操守。</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text02",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
