<template>
	<div class="content my-content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back" @click="$router.go(-1)">
				<img src="../../../assets/images/home/expert/back.png" alt="" />
			</div>
			<div class="header-title">我的报告</div>
			<div class="home" @click="goPage('/index')">
				<img src="../../../assets/images/home/home.png" alt="" />
			</div>
		</div>
		<div class="report pull-content">
			<ul class="report-nav">
				<li :class="{ active: isShow }" @click="isShow = !isShow">
					个性特质分析
				</li>
				<li :class="{ active: !isShow }" @click="isShow = !isShow">
					专业类匹配度
				</li>
			</ul>
			<div class="report-item-all">
				<div class="report-item" v-show="isShow">
					<div class="report-relation">
						<span>{{ evaluationData.student.student_name }}</span>
						<span>{{ evaluationData.student.student_native_str }}</span>
						<span v-show="evaluationData.evaluation.role_type == 'student'">考生测评</span>
						<span v-show="evaluationData.evaluation.role_type == 'parents'">亲属测评</span>
						<span>{{ evaluationData.student.done_at }}{{ evaluationData.evaluation.done_at }}</span>
					</div>
					<div class="report-box3 report-box">
						<div class="report-title">
							一、{{evaluationData.evaluation.report.base.profession_concept.summary}}
							<!-- 一、{{baseList1.summary}} -->
						</div>
						<span class="report-h2">
							{{evaluationData.evaluation.report.base.profession_concept.reportTextList.summary}}
							<!-- {{baseList1.reportspanList.summary}} -->
						</span>
						<span class="txt2">
							{{evaluationData.evaluation.report.base.profession_concept.reportTextList.keywords_text}}
							<!-- {{baseList1.reportspanList.keywords_text}} -->
						</span>
						<span class="report-h3">
							{{evaluationData.evaluation.report.base.profession_concept.reportTextList.description}}
							<!-- {{baseList1.reportspanList.description}} -->
						</span>
						<div id="intellect" ref="profession_concept"></div>
					</div>
					<div class="report-box1 report-box">
						<div class="report-title">
							二、{{evaluationData.evaluation.report.base.intellect.summary}}
							<!-- 二、{{baseList1.summary}} -->
						</div>
						<span class="report-h2">
							{{evaluationData.evaluation.report.base.intellect.reportTextList.summary}}
							<!-- {{baseList1.reportspanList.summary}} -->
						</span>
						<span class="txt2">
							{{evaluationData.evaluation.report.base.intellect.reportTextList.keywords_text}}
							<!-- {{baseList1.reportspanList.keywords_text}} -->
						</span>
						<span class="report-h3">
							{{evaluationData.evaluation.report.base.intellect.reportTextList.description}}
							<!-- {{baseList1.reportspanList.description}} -->
						</span>

						<div id="intellect" ref="intellect"></div>
					</div>

					<div class="report-box4 report-box">
						<div class="report-title">
							三、{{evaluationData.evaluation.report.base.profession_interest.name}}
							<!-- 三、{{baseList1.summary}} -->
						</div>
						<span class="report-h2">
							{{evaluationData.evaluation.report.base.profession_interest.reportTextList.eminent_person_text}}
							<!-- {{baseList1.reportspanList.eminent_person_text}} -->
						</span>
						<span class="txt2">
							{{evaluationData.evaluation.report.base.profession_interest.reportTextList.keywords_text}}
							<!-- {{baseList1.reportspanList.keywords_text}} -->
						</span>
						<span class="report-h3">
							{{evaluationData.evaluation.report.base.profession_interest.reportTextList.description}}
							<!-- {{baseList1.reportspanList.description}} -->
						</span>
						<div id="intellect" ref="profession_interest"></div>
					</div>
					<div class="report-box2 report-box">
						<div class="report-title">
							四、{{ evaluationData.evaluation.report.base.mbti_character.name }}
							<!-- 四、{{baseList1.summary}} -->
						</div>
						<span class="report-h2">
							{{evaluationData.evaluation.report.base.mbti_character.reportTextList.eminent_person_text}}
							<!-- {{baseList1.reportspanList.eminent_person_text}} -->
						</span>
						<span class="txt2">
							{{evaluationData.evaluation.report.base.mbti_character.reportTextList.keywords_text}}
							<!-- {{baseList1.reportspanList.keywords_text}} -->
						</span>
						<span class="report-h3">
							{{evaluationData.evaluation.report.base.mbti_character.reportTextList.description}}
							<!-- {{baseList1.reportspanList.description}} -->
						</span>
						<dir class="report-box4-ex">
							<div v-for="item in arrData" :key="item">
								<p>{{item.A.factor_name}}</p>
								<div class="content-ex">
									<div>
										<p :style="{width: proportion(item.A.avg_score, (item.A.avg_score-0)+(item.B.avg_score-0)),}" v-if="(item.A.avg_score-0)>=(item.B.avg_score-0)"></p>
									</div>
									<div>
										<p v-if="(item.A.avg_score-0)<=(item.B.avg_score-0)" :style="{width: proportion(item.B.avg_score, (item.A.avg_score-0)+(item.B.avg_score-0))}"></p>
									</div>
								</div>
								<p>{{item.B.factor_name}}</p>
							</div>
						</dir>
					</div>

					<div class="report-box5 report-box">
						<div class="report-title">
							五、{{ evaluationData.evaluation.report.base.subjects.summary }}
							<!-- 五、{{baseList1.summary}} -->
						</div>
						<span class="report-h2">
							{{evaluationData.evaluation.report.base.subjects.reportTextList.eminent_person_text}}
							<!-- {{baseList1.reportspanList.eminent_person_text}} -->
						</span>
						<span class="txt2">
							{{evaluationData.evaluation.report.base.subjects.reportTextList.keywords_text}}
							<!-- {{baseList1.reportspanList.keywords_text}} -->
						</span>
						<span class="report-h3">
							{{evaluationData.evaluation.report.base.subjects.reportTextList.description}}
							<!-- {{baseList1.reportspanList.description}} -->
						</span>
						<div id="intellect" ref="subjects"></div>
					</div>
				</div>
				<!-- 专业类匹配度 -->
				<div class="report-item" v-show="!isShow">
					<div class="report-exponent" v-for="(item,index) in majorList" :key="index">
						<div class="exponent-title">
							<div class="exponent-star">
								推荐指数：<span v-for="(itemk,indexk) in item.stars" :key="indexk">★</span>
								<!-- <span a:for="{{item.stars}}">★</span> -->
							</div>
							<div class="percentage">匹配度：{{item.degree}}
								<!-- {{item.degree}} -->
							</div>
						</div>
						<div class="exponent-list">
							<div class="exponent-item" v-for="(item2,index2) in item.majors" :key="index2">
								<span>
									{{item2.name}}<br />
									({{item2.childrenSize}})
									<!-- {{item2.name}}<br />
									({{item2.childrenSize}}) -->
								</span>
								<div class="exponent-item-son">
									<span v-for="(item3,index3) in item2.children" :key="index3">{{item3.name}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		name: "report",
		data() {
			return {
				isScrollTop: false,
				isShow: true, //切换报告
				roleTType: "student", //报告人类型
				evaluationData: {},
				arrData: "",
				majorList:[], //报告列表
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		mounted() {
			this.getData();
		},
		methods: {
			proportion(obj, max) {
				console.log(obj, max, "---")
				return ((Math.abs(obj) - 0) / (max - 0)) * 100 + "%";
			},
			// 页面跳转
			goPage(path) {
				this.$router.push(path);
			},
			drawLine(dataNmae, dataNum, title) {
				// 基于准备好的dom，初始化echarts实例
				setTimeout(() => {
					let myChart = echarts.init(this.$refs[title]);
					// 绘制图表
					let option = {
						grid: {},
						xAxis: {
							data: dataNmae,
							type: "category",
							axisLabel: {
								interval: 0,
								rotate: 60
							},
						},

						yAxis: {
							type: "value",
						},

						series: [{
							data: dataNum,
							type: "bar",
							itemStyle: {
								normal: {
									label: {
										show: true, //开启显示
										position: "top", //在上方显示
										textStyle: {
											//数值样式
											color: "#666",
											fontSize: 12,
										},
									},
								},
							},
						}, ],
					};
					myChart.setOption(option);
				}, 10);
			},
			leidata(valueArr, indicator, title) {
				console.log(valueArr, indicator);
				setTimeout(() => {
					let myChart = echarts.init(this.$refs[title]);
					//   let valueArr = [];
					//   let indicator = [];
					//   let sum = 0;
					let option = {
						radar: {
							// shape: 'circle',
							indicator: indicator,
							// [
							//   { name: "Sales", max: 9 },
							//   { name: "Administration", max: 9 },
							//   { name: "Information Technology", max: 9 },
							//   { name: "Customer Support", max: 9 },
							//   { name: "Development", max: 9 },
							//   { name: "Marketing", max: 9 },
							// ],
						},
						series: [{
							name: "Budget vs spending",
							type: "radar",
							data: [{
								//   value: [4200, 3000, 20000, 35000, 50000, 18000],
								value: valueArr,
								name: "Allocated Budget",
							}, ],
						}, ],
					};

					myChart.setOption(option);
				}, 1000);
			},
			getData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/evaluation/evaluation-info", {
						id: this.$route.query.id,
					})
					.then(function(res) {
						console.log(res,111111);
						that.evaluationData = res.data.data;
						that.majorList = that.evaluationData.evaluation.report.major;
						console.log(that.majorList,123)
						let dataName = [];
						let dataNun = [];
						for (
							let index = 0; index <
							that.evaluationData.evaluation.report.base.intellect.reportData
							.length; index++
						) {
							dataNun.push(
								that.evaluationData.evaluation.report.base.intellect.reportData[
									index
								].avg_score
							);
							dataName.push(
								that.evaluationData.evaluation.report.base.intellect.reportData[
									index
								].factor_name
							);
						}
						that.drawLine(dataName, dataNun, "intellect");
						let dataNamea = [];
						let dataNuna = [];
						for (
							let index = 0; index <
							that.evaluationData.evaluation.report.base.profession_concept
							.reportData.length; index++
						) {
							dataNuna.push(
								that.evaluationData.evaluation.report.base.profession_concept
								.reportData[index].avg_score
							);
							dataNamea.push(
								that.evaluationData.evaluation.report.base.profession_concept
								.reportData[index].factor_name
							);
						}
						that.drawLine(dataNamea, dataNuna, "profession_concept");
						let dataNameb = [];
						let dataNunb = [];
						for (
							let index = 0; index <
							that.evaluationData.evaluation.report.base.profession_interest
							.reportData.length; index++
						) {
							dataNunb.push(
								that.evaluationData.evaluation.report.base.profession_interest
								.reportData[index].avg_score
							);
							dataNameb.push({
								name: that.evaluationData.evaluation.report.base
									.profession_interest.reportData[index].factor_name,
								max: 9,
							});
						}

						that.leidata(dataNunb, dataNameb, "profession_interest");

						let dataNameC = [];
						let dataNunC = [];
						for (
							let index = 0; index <
							that.evaluationData.evaluation.report.base.subjects.reportData
							.length; index++
						) {
							dataNunC.push(
								that.evaluationData.evaluation.report.base.subjects.reportData[
									index
								].avg_score
							);
							dataNameC.push({
								name: that.evaluationData.evaluation.report.base.subjects
									.reportData[index].factor_name,
								max: 9,
							});
						}

						that.leidata(dataNunC, dataNameC, "subjects");

						let arrData = [{
								A: "",
								B: "",
							},
							{
								A: "",
								B: "",
							},
							{
								A: "",
								B: "",
							},
							{
								A: "",
								B: "",
							},
						];
						let arrAD = that.evaluationData.evaluation.report.base.mbti_character.reportData

		 			// console.log((arrAD[0].factor_subgroup_id-0)-1);
						for (
							let index = 0; index < arrAD.length; index++) {
							if (arrData[(arrAD[index].factor_subgroup_id - 0) - 1].A) {
								arrData[(arrAD[index].factor_subgroup_id - 0) - 1].B = arrAD[index]
							} else {

								arrData[(arrAD[index].factor_subgroup_id - 0) - 1].A = arrAD[index]
							}

						}
						console.log(arrData)
						that.arrData = arrData
					});
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
	#intellect {
		width: 100%;
		height: 320px;
	}
	.home,
	.home img{
		width: .4rem;
		height: .4rem;
	}
	.home{
		width: .32rem;
		height: .32rem;
	}
	.report-box4-ex{
		margin: .6rem 0;
	}
	.report-box4-ex>div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: .4rem 0;
		color: #666666;
		font-size: .28rem;
	}

	.content-ex {
		width: 80%;
		height: 0.4rem;
		display: flex;
	}

	.content-ex>div {
		width: 50%;
		height: 100%;
	}

	.content-ex>div>p {
		background-color: #ffe3db;
		border: .04rem solid #e0b280;
		width: 0;
		height: 100%;
	}
	.content-ex>div:nth-of-type(1)>p{
		border-right: 0;
	}
	.content-ex>div:nth-of-type(2)>p{
		background-color: #b7e46a;
		border: .04rem solid #80b02e;
		border-left: 0;
	}

	.content-ex> :nth-child(1) {
		display: flex;
		justify-content: flex-end;
	}
</style>
