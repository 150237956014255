<template>
	<div class="content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back" @click="$router.go(-1)">
				<img src="../../assets/images/home/expert/back.png" alt="" />
			</div>
			<div class="header-title">手机验证码</div>
		</div>

		<div class="verification pull-content list-section">
			<div class="verification-txt">
				<p>输入验证码</p>
				<span>验证码已发送至 +86 {{ phoneData }}</span>
				<div class="result">
					<div class="security-code-wrap">
						<label for="code">
							<ul class="security-code-container">
								<li class="field-wrap" v-for="(item, index) in number" :key="index">
									<i class="char-field">{{ value[index] || placeholder }}</i>
								</li>
							</ul>
						</label>
						<input ref="input" class="input-code" @keyup="handleInput($event)" v-model="value" id="code"
							name="code" type="tel" :maxlength="number" autocorrect="off" autocomplete="off"
							autocapitalize="off" />
					</div>
				</div>
				<span v-show="!show">{{count}}秒后可重新获取验证码</span>
				<div v-show="show" @click="getCode()" class="getCode">获取验证码</div>
				<div v-show="!show" @click="checkCode()" class="getCode">完成</div>
			</div>
		</div>
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "login-verification",
		props: {
			number: {
				type: Number,
				default: 4,
			},
			placeholder: {
				type: String,
				default: "-",
			},
		},
		data() {
			return {
				isScrollTop: false,
				phoneData: "", //手机号
				verification: "", //验证码
				value: "",
				show: false, //显示获取验证码
				count: "", //剩余时间
				timer: null,
				userType: 0,
				isToast:false,
				toastText:'',
				source:'',
			};
		},
		beforeCreate() {},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			this.source = JSON.parse(localStorage.getItem("source"));
			this.phoneData = this.$route.query.phone
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.count = TIME_COUNT;
				this.show = false;
				this.timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
					} else {
						this.show = true;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}
		},
		mounted() {
			
		},
		computed: {},
		methods: {
			hideKeyboard() {
				// 输入完成隐藏键盘
				var that = this;
				document.activeElement.blur(); // ios隐藏键盘
				this.$refs.input.blur(); // android隐藏键盘

				this.axios
					.post(this.HOME + "/api/user/login-by-Phone", {
						phone: this.$route.query.phone,
						sms_code: this.value,
					})
					.then(function(res) {
						console.log(res.data,252525)
						localStorage.userData = JSON.stringify(res.data.data);
						localStorage.token = JSON.stringify(res.data.data.token);
						if (res.data.code == 0) {
							console.log(res.data.code,'缓存123')
							that.getUserData();
						} else {
							that.toastText = '验证码错误！';
							that.isToast = true;
							setTimeout(()=> {
								that.isToast = false
							}, 1000)
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 获取用户信息
			getUserData() {
				let that = this;
				this.axios.post(this.HOME + "/api/user/center-info").then(function(res) {
					that.userData = res.data.data;
					localStorage.centerUserData = JSON.stringify(res.data.data);
					if(res.data.data.type == 1){
						that.$router.push('/consultant');
					}else{
						if(that.source){
							window.localStorage.removeItem('source');
							that.$router.push('/evaluation/entrance');
						}else{
							that.$router.push('/index');
						}
					}
				});
			},
			
			handleSubmit() {
				this.$emit("input", this.value);
			},
			handleInput(e) {
				this.$refs.input.value = this.value;
				if (this.value.length == this.number) {
					this.hideKeyboard();
				}
				this.handleSubmit();
			},
			checkCode(){
				console.log(this.value,'输入的验证码')
				if (this.value.length == this.number) {
					this.hideKeyboard();
				}else{
					this.toastText = '验证码位数不正确！';
					this.isToast = true;
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
				}
			},
			// 倒计时60s
			getCode() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000);
					this.axios
						.post(this.HOME + "/api/common/send-sms-captcha", {
							phone: this.$route.query.phone,
							type: "login",
						})
						.then(function() {})
						.catch(function(error) {
							console.log(error);
						});

				}
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
</style>
