<template>
	<div class="content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back"></div>
			<div class="header-title">登录</div>
		</div>
		<div class="login pull-content list-section">
			<div class="login-txt">
				<div class="logo">
					<img src="../assets/images/login/logo.png" alt="" />
				</div>
				<div class="company">有谱志愿</div>
				<div class="phone-number" v-if="mode == 1">
					<span>手机号</span>
					<input type="text" v-model="phoneNumber" placeholder="请输入手机号"/>
				</div>
				<div class="agreement">
					<span :class="{ checked: isAgreement }" @click="isAgreement = !isAgreement"><img
							v-show="isAgreement" src="../assets/images/login/yes.png" alt="" /></span>
					<p>
						我已阅读并同意<span>《有谱志愿用户协议》</span>、<span>《隐私政策》</span>，未注册将引导完成账号注册
					</p>
				</div>
				<div class="login-btns">
					<span @click="nailLogin()" v-if="mode == 0" class="nail-btn"><img src="../assets/images/login/nail.png" />钉钉授权登录</span>
					<span @click="checkPhone()" v-if="mode == 1">获取验证码</span>
				</div>
			</div>
			<div :class="{'login-mode':true,'noPositin':noPositin}" v-if="mode==0">
				<div class="mode-title"><span></span>其他登录方式<span></span></div>
				<ul class="mode-item">
					<li>
						<span @click="changeMode(0)"><img src="../assets/images/login/nail.png" /></span>
						<p :class="{ active: mode == 0 }">当前登录方式</p>
					</li>
					<li>
						<span @click="changeMode(1)"><img src="../assets/images/login/phone.png" /></span>
						<p :class="{ active: mode == 1 }">当前登录方式</p>
					</li>
				</ul>
			</div>
		</div>
		<!-- 阅读协议弹窗 -->
		<div class="my-confirm-bg" v-show="openAgreement" @click="openAgreement = !openAgreement"></div>
		<div class="my-confirm my-confirm2" v-show="openAgreement">
			<p>您还未阅读并同意授权</p>
			<div class="confirm-btns">
				<span @click="openAgreement = false">确认</span>
			</div>
		</div>
		<!-- 钉钉授权登录弹窗 -->
		<div class="addr-select-bg" v-show="openEmpower" @click="openEmpower = !openEmpower"></div>
		<div class="nail-popup" v-show="openEmpower">
			<div class="nail-popup-title">
				<span><img src="../assets/images/login/logo.png" alt="" /></span>
				<p>有谱志愿</p>
				<em>申请</em>
			</div>
			<p>获取您的钉钉昵称</p>
			<div class="nick-name">
				<span>钉钉昵称</span>
				<p>张三<img src="../assets/images/login/green-yes.png" alt="" /></p>
			</div>
			<div class="nail-popup-btns">
				<span @click="isEmpower()">同意</span>
				<span @click="noEmpower()">拒绝</span>
			</div>
		</div>
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
		<!-- 手机验证弹窗 -->
		<div class="my-confirm-bg" v-show="openPhoneTips" @click="openPhoneTips = !openPhoneTips"></div>
		<div class="my-confirm my-confirm2" v-show="openPhoneTips">
			<p>{{ tipsMsg }}</p>
			<div class="confirm-btns confirm-btns-one">
				<span @click="phoneLogin()">是</span>
				<span @click="openPhoneTips=false,phoneNumber=''">否</span>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import "../../public/js/count.js";
	export default {
		name: "login",
		data() {
			return {
				isScrollTop: false,
				tipsMsg: '',
				toastText: '',
				isToast: false,
				mode: 1, //登录方式
				openAgreement: false, //阅读协议弹窗
				isAgreement: true, //阅读协议状态
				openEmpower: false, //钉钉授权状态
				openPhoneTips: false, //验证手机号弹出框
				phoneNumber: "",
				phone: "",
				noPositin: false,
				
				defaultPhoneHeight : '0',  //默认屏幕高度
				nowPhoneHeight :  '0',  //实时屏幕高度
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			if(JSON.parse(localStorage.getItem("ddEntrance")) == 'yes'){
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:"clearAll"});
			}
		},
		mounted(){
		    this.defaultPhoneHeight = window.innerHeight
		    window.onresize = ()=>{
		        this.nowPhoneHeight = window.innerHeight
		    }
		},
		watch: {
		    nowPhoneHeight:function(){
		         if(this.defaultPhoneHeight != this.nowPhoneHeight){
					this.noPositin = true;
		        }else{
		            this.noPositin = false;
		        }
		    }
		},
		methods: {
			// 钉钉授权登录
			nailLogin() {
				if (this.isAgreement) {
					this.openEmpower = true;
				} else {
					this.openAgreement = true;
				}
			},
			// 验证手机号是否存在
			checkPhone(){
				let that = this;
				var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if (this.phoneNumber == "") {
					this.toastText = '请填写您的手机号码！',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
					return false;
				} else if (!reg_tel.test(this.phoneNumber)) {
					this.toastText = '请正确填写您的手机号码！',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
					return false;
				}
				this.axios
					.post(this.HOME + "/api/user/has-phone-user", {
						phone: this.phoneNumber
					})
					.then(function(res) {
						that.phoneLogin();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 手机号登录
			phoneLogin() {
				let that = this;
				that.openPhoneTips = false,
				this.axios
					.post(this.HOME + "/api/common/send-sms-captcha", {
						phone: this.phoneNumber,
						type: "login",
					})
					.then(function() {
						that.$router.push({
							path: "/verification",
							query: {
								phone: that.phoneNumber,
							},
						});
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			// 同意钉钉授权
			isEmpower() {
				this.openEmpower = false;
			},
			//拒绝钉钉授权
			noEmpower() {
				this.openEmpower = false;
			},
			// 改变登录方式
			changeMode(mode) {
				this.mode = mode;
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
			window.onresize = null;
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
	
</style>
