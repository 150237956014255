import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../views/login.vue' //登录
import index from '../views/index/index.vue' //首页
import verification from '../views/login/verification.vue' //手机验证
import start from '../views/home/start/index.vue' //开屏

import expert from '../views/home/expert/index.vue' //专家列表
import expertdetail from '../views/home/expert/detail.vue' //专家详情
import ask from '../views/home/expert/ask.vue' //向TA提问

import evaluation from '../views/home/evaluation/index.vue' //测评
import question from '../views/home/evaluation/question.vue' //测评问题
import entrance from '../views/home/evaluation/entrance.vue' //测频入口

import ucenter from '../views/user/ucenter/index.vue' //我的
import information from '../views/user/information/index.vue' //完善考生信息
import feedback from '../views/user/feedback/index.vue' //意见与反馈
import info from '../views/user/info/index.vue' //个人信息
import report from '../views/user/report/index.vue' //我的报告

import consultant from '../views/consultant/index.vue' //咨询师信息
import addcase from '../views/consultant/case.vue' //咨询师信息
import edit from '../views/consultant/edit.vue' //修改基本信息
import editlist from '../views/consultant/list.vue' //预览列表
import editdetail from '../views/consultant/detail.vue' //预览详情
//有谱快看
import live from '../views/home/live/index.vue' 
import video01 from '../views/home/live/video01.vue' 
import video02 from '../views/home/live/video02.vue' 
import video03 from '../views/home/live/video03.vue' 

// 头条文章
import headlines from '../views/home/headlines/index.vue'
import text01 from '../views/home/headlines/text01.vue'
import text02 from '../views/home/headlines/text02.vue'
import text03 from '../views/home/headlines/text03.vue'
import text04 from '../views/home/headlines/text04.vue'
import text05 from '../views/home/headlines/text05.vue'
import text06 from '../views/home/headlines/text06.vue'

// 计划
import texta from '../views/home/plan/texta.vue'
import textb from '../views/home/plan/textb.vue'
import textc from '../views/home/plan/textc.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'start',
		component: start
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/index',
		name: 'index',
		component: index
	},
	{
		path: '/verification',
		name: 'verification',
		component: verification
	},

	{
		path: '/expert',
		name: 'expert',
		component: expert
	},
	{
		path: '/expert/detail',
		name: 'expert-detail',
		component: expertdetail
	},
	{
		path: '/expert/ask',
		name: 'expert-ask',
		component: ask
	},
	{
		path: '/evaluation',
		name: 'evaluation',
		component: evaluation
	},
	{
		path: '/evaluation/question',
		name: 'evaluation-question',
		component: question
	},
	{
		path: '/evaluation/entrance',
		name: 'evaluation-entrance',
		component: entrance
	},
	{
		path: '/ucenter',
		name: 'ucenter',
		component: ucenter
	},
	{
		path: '/information',
		name: 'information',
		component: information
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: feedback
	},
	{
		path: '/info',
		name: 'info',
		component: info
	},
	{
		path: '/report',
		name: 'report',
		component: report
	},
	{
		path: '/consultant',
		name: 'consultant',
		component: consultant
	},
	{
		path: '/case',
		name: 'case',
		component: addcase
	},
	{
		path: '/edit',
		name: 'edit',
		component: edit
	},
	{
		path: '/edit/list',
		name: 'edit-list',
		component: editlist
	},
	{
		path: '/edit/detail',
		name: 'edit-detail',
		component: editdetail
	},
	{
		path: '/live',
		name: 'live',
		component: live
	},
	{
		path: '/headlines',
		name: 'headlines',
		component: headlines
	},
	{
		path: '/text01',
		name: 'text01',
		component: text01
	},
	{
		path: '/text02',
		name: 'text02',
		component: text02
	},
	{
		path: '/text03',
		name: 'text03',
		component: text03
	},
	{
		path: '/text04',
		name: 'text04',
		component: text04
	},
	{
		path: '/text05',
		name: 'text05',
		component: text05
	},
	{
		path: '/text06',
		name: 'text06',
		component: text06
	},
	{
		path: '/texta',
		name: 'texta',
		component: texta
	},
	{
		path: '/textb',
		name: 'textb',
		component: textb
	},
	{
		path: '/textc',
		name: 'textc',
		component: textc
	},
	{
		path: '/video01',
		name: 'video01',
		component: video01
	},
	{
		path: '/video02',
		name: 'video02',
		component: video02
	},
	{
		path: '/video03',
		name: 'video03',
		component: video03
	}
	

]

const router = new VueRouter({
	mode: 'hash',//history
	base: process.env.BASE_URL,
	routes
})

export default router
