<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">专项计划</div>
		</div>
		<div class="headlines-txt plan-txt">
			<h2>港大/科大/浸会/教育/岭南面试培训安排</h2>
			<p>港校面试是钉钉阿里云教育生涯规划中面试培训品牌之一。专业过硬的教学、教务服务团队及我们对各所高校面试信息的精准把握，是我们确保高通过率的根本！</p>
			<img src="../../../assets/images/home/plan/301.png" alt="">
			<p>团队老师有着丰富的经验，专家团队老师的面试通过率持续上升：其中，2017年取得部分港校86%的高通过率，2018年港校面试6月香港高校实录人数48人（统计到的录取人数总共56人），其中2名获得香港大学64.4万全额奖学金。2019年优朗再创佳绩，其中在1月香港大学卓越计划面试中已有66人获得有条件录取，相比去年增幅60%；6月港校面试总共录取49人（不含港中深），其中，香港大学录取人数中每15位就有1位出自团队教师，3位获得全奖，共斩获奖学金266万元！2021年6月港校面试培训课表正式发布，接受提前预报名！具体培训内容详见下文：</p>
			<h2>授课内容</h2>
			<p>·面试自我介绍的优化及面试官涉及到个人单面问题讲解及合理的回答方式，突出考生独特个性；</p>
			<p>·基本面试礼仪讲解，展现当代考生良好的气质与教养；</p>
			<p>·关于本年热门经济类、政治类话题的思维训练及思路拓展，与第一轮小组讨论面试模拟；</p>
			<p>·关于科技类、文化类及社会类热点话题的年度热点归纳及思维拓展，同时进行第五大类面试必备话题超强预测，并进行第三轮小组讨论的模拟演练，针对每位学员的讨论，现场点评，掌握各类题型的面试技巧；</p>
			<p>·结合港校历年面试真题，多轮头脑风暴实战演练，着重提升考生的团队协作能力、想象能力、创新能力、认知能力、观察能力、心理素质、领导力等综合素质。</p>
			<h2>授课形式</h2>
			<p>面授班12人左右，互动实战性教学。</p>
			<p>网络班10人左右，由专业面试教研团队授课。</p>
			<p>VIP一对一，专业定制面试培训方案（根据学员时间排课，需提前预定）。</p>
			<h2>师资力量</h2>
			<p><strong>Kate 陈</strong></p>
			<p>上外教育硕士，《面试思维风暴》主编，优朗港校面试首席杰出讲师，著名高校面试讲师，定期参加港校学术交流研讨活动。10年专注港校本科面试，港校硕士面试。对面试类问题分析有独到的见解思维应变能力强，善于快速开拓学员的思维能力，团队协作等能力。</p>
			
			<p><strong>Alex 李</strong></p>
			<p>外国语言学及英语语言学硕士，《高校面试口语》主编之一，十多年丰富的口语授课经验。教学耐心负责，善于因材施教，循序渐进。课堂自然流畅，文雅不失风度。对面试类问题分析有独到的见解思维应变能力强，善于快速开拓学员的思维能力，团队协作等能力。</p></p></p>
			
			<p><strong>Tina 张</strong></p>
			<p>从事留学考试培训7年之久，杭州唯一官方英语角的组织者，荣获中高级口语证书和口语段位赛第九段（最高段位）。《高校面试口语》主编之一，有丰富的口语面试教学经验，授课认真负责，风趣幽默，能够和学生保持亦师亦友的良好关系。</p>
			
			<p><strong>Grace 张</strong></p>
			北师大语言学应用硕士，优朗港校面试首席讲师，著名高校副教授。中国先秦史学会国学双语研究会理事，世界汉语大会国外教育部长随行翻译，华尔街某金融公司CEO访谈节目现场口译官；13年从业经验，9年专注港校面试经验；上课方式灵活多样，善于激发学员的学习积极性，互动性强，课堂极具感染力。</p></p>
			
			<p><strong>Leo 刘</strong></p>
			<p>英国海归硕士，以最高等级学位毕业于两所英国TOP10顶尖大学，并多次在国内英语风采大赛中获奖。教学经验丰富，深谙英文面试考核重难点，授课颇具亲和力，深受学员喜爱。</p>
			
			<h2>单击以下二维码，弹出后识别即可联系规划师</h2>
			<div class="ewm" @click="downImg()" v-if="ddEntrance == 'yes'">
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" alt="">
			</div>
			<div class="ewm" v-else>
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" v-image-preview alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "textc",
		data() {
			return {
				ddEntrance:'', //钉钉进入
			}
		},
		beforeCreate() {
		},
		created() {
			if(JSON.parse(localStorage.getItem("ddEntrance"))){
				this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			}
		},
		methods: {
			// 下载二维码事件
			downImg() {
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:'downImg',url: 'http://aspevel.scdxtc.cn/img/dysxewm.jpg'});
			},
		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.plan-txt{
		padding-top: 1.3rem;
	}
	.headlines-txt>h2{
		font-size: .34rem;
		line-height: 1.5;
		text-align: center;
		margin-top: .3rem;
	}
	.plan-txt>h2:first-of-type{
		margin-top: 0;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
	.ewm{
		width: 100%;
	}
	.ewm img{
		width: 100%;
	}
</style>