<template>
	<div class="content my-content">
		<!-- 头部 -->
		<div :class="{'header':true,'scroll white':isScrollTop,'white':true}">
			<div class="back" @click="$router.go(-1)"><img src="../../assets/images/home/expert/back.png" alt=""></div>
			<div class="header-title">{{title}}</div>
		</div>
		<div class="case pull-content">
			<div class="case-cover">
				<p>封面图：</p>
				<div class="cover-img">
					<img v-if="myAvatar" :src="host + myAvatar">
					<img v-else-if="headImg" :src="host + headImg">
				</div>

				<div class="cover-btn">
					<input class="inputB" @change="imgIny($event)" type="file" />
					上传
				</div>
			</div>
			<ul class="case-msg">
				<li>
					<p>标题：</p>
					<input type="text" v-model="caseTitle" placeholder="请输入标题" />
				</li>
				<li>
					<p>学生姓名：</p>
					<input type="text" v-model="student_name" placeholder="请输入学生姓名" />
				</li>
				<li>
					<p>是否主案例</p>
					<div class="course-list key-list">
						<span :class="{'checked':isIndex==1}" @click="isIndex=1">是</span>
						<span :class="{'checked':isIndex!==1}" @click="isIndex=0">否</span>
					</div>
				</li>
				<li>
					<p>录取结果：</p>
					<textarea v-model="result_remarks" placeholder="请输入录取结果"></textarea>
				</li>
				<li>
					<p>专业定位：</p>
					<textarea v-model="location_remarks" placeholder="请输入专业定位"></textarea>
				</li>
				<li>
					<p>其他说明：</p>
					<textarea v-model="other_remarks" placeholder="请输入其他说明"></textarea>
				</li>
				<li>
					<p>客户反馈：</p>
					<textarea v-model="account_remarks" placeholder="请输入客户反馈"></textarea>
				</li>
			</ul>
			<div class="case-btn" @click="addCase()">确认</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "case",
		data() {
			return {
				isScrollTop: false,
				title: '',
				myAvatar: '',
				gName: '',
				gPhone: '',
				caseData: [{
						title: '是'
					},
					{
						title: '否'
					}
				],
				checkedIndex: 0,
				headImg: '', //案例封面图
				caseTitle: '', //案例标题
				student_name: '', //考生姓名（限制：100字）
				result_remarks: '', //录取结果
				location_remarks: '', //专业定位
				other_remarks: '', //其他说明
				account_remarks: '', //客户反馈
				isIndex: 1, //是否为主案例（咨询师详情页展示案例），0否，1是。
				id: '' //案例id
			}
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		mounted() {
			let anliObj = (JSON.parse(localStorage.getItem("anliObj")));
			console.log(anliObj, 1234)
			if (anliObj) {
				this.id = anliObj.id,
					this.isIndex = anliObj.is_main,
					this.caseTitle = anliObj.title,
					this.headImg = anliObj.cover,
					this.student_name = anliObj.student_name,
					this.result_remarks = anliObj.result_remarks,
					this.location_remarks = anliObj.location_remarks,
					this.other_remarks = anliObj.other_remarks,
					this.account_remarks = anliObj.account_remarks,
					this.title = '编辑案例'
			} else {
				this.title = '新增案例'
			}

		},
		methods: {
			// 上传封面图
			imgIny(e) {
				let that = this;
				const files = e.target.files;
				console.log(files[0]);
				console.log({
					image: files[0]
				});
				var fromdata = new FormData();
				fromdata.append("image", files[0]);
				this.axios
					.post(this.HOME + "/api/file/upload/image", fromdata, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(function(res) {
						that.myAvatar = res.data.data.src;
						console.log(that.myAvatar, 123);
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			// 编辑案例
			editEv() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/expert/edit-expert-case", {
						id: that.id, //	当前需要编辑的案例ID
						title: that.caseTitle, //标题
						cover: that.myAvatar?that.myAvatar:that.headImg, //封面图地址（限制：250字），有图片上传接口获取
						is_main: that.isIndex, //是否为主案例（咨询师详情页展示案例），0否，1是。
						student_name: that.student_name, //考生姓名（限制：100字）
						result_remarks: that.result_remarks, //录取结果
						location_remarks: that.location_remarks, //专业定位
						other_remarks: that.other_remarks, //其他说明
						account_remarks: that.account_remarks //客户反馈
					})
					.then(function(res) {
						alert("编辑案例成功");
						that.$router.push('/consultant');
						console.log(res);
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			// 新增案例
			addCase() {
				let that = this;
				if (that.id) {
					that.editEv();
				} else {
					that.headImg = that.myAvatar;
					this.axios
						.post(this.HOME + "/api/expert/add-expert-case", {
							title: that.caseTitle, //标题
							cover: that.headImg, //封面图地址（限制：250字），有图片上传接口获取
							is_main: that.isIndex, //是否为主案例（咨询师详情页展示案例），0否，1是。
							student_name: that.student_name, //考生姓名（限制：100字）
							result_remarks: that.result_remarks, //录取结果
							location_remarks: that.location_remarks, //专业定位
							other_remarks: that.other_remarks, //其他说明
							account_remarks: that.account_remarks //客户反馈
						})
						.then(function(res) {
							alert("新增案例成功");
							that.$router.push('/consultant');
							console.log(res);
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			}
		},


		// 滚动改变样式
		eventScrollTop() {
			let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
			if (scrollTop >= 5) {
				if (this.isScroll) {
					this.isScroll = false;
					this.isScrollTop = true;
				}
			} else {
				if (!this.isScroll) {
					this.isScroll = true;
					this.isScrollTop = false;
				}
			}
		},

	destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop)
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		}
	}
</script>

<style scoped>

</style>
