<template>
    <div class="content main-content">
		<div class="evaluation pull-content">
			<div class="evaluation-list">
				<div class="evaluation-item" v-for="(item,index) in evaluationList" :key="index">
					<h2>{{item.title}}</h2>
					<div class="evaluation-txt">
						<span><img :src="host + item.cover"></span>
						<p>
							<span :class="item.description?'':'other'">{{item.summary}}</span>
							<em v-if="item.description" @click="openAll(index)">展开全部<img src="../../../assets/images/home/index/arrow-more.png"></em>
						</p>
					</div>
					<div class="evaluation-bottom">
						<span>测评时间：<em>{{item.expect_time}}</em></span>
						<span>测评人数：<em>{{item.usersCount}}人</em></span>
						<span @click="toEntrance(item.title,item.factor_all)" :class="item.factor_all==1?'cur':''">马上测试</span>
					</div>
					
					<!-- 查看全部 -->
					<div class="my-confirm-bg" v-if="showIndex==index" @click="showIndex=-1"></div>
					<div class="all-text" v-html="item.description" v-if="showIndex==index"></div>
				</div>
			</div>
		</div>
		<!-- 提示 -->
		<div class="addr-select-bg" v-show="isTips" @click="isTips=!isTips"></div>
		<div class="evaluation-tips" v-show="isTips">
			<ul>
				<li><span></span><p>根据你的实际情况作答并以最快速度完成 每道问题；</p></li>
				<li><span></span><p>选择没有“对”与“错”之分；</p></li>
				<li><span></span><p>选择更接近你平时的感受或行为的那项；</p></li>
				<li><span></span><p>选择你是怎么做的，而不要选择你想要怎 样，以为会怎样;</p></li>
			</ul>
			<div class="tips-btn" @click="noTips()">下次不再提醒</div>
		</div>
		
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
		<!-- 尾部 -->
		<tabbar :current="3"></tabbar>
    </div>
</template>

<script>
	import tabbar from '../../../components/tabbar.vue';
	import "../../../../public/js/count.js";
    export default {
        name: "evaluation",
		components: {
			tabbar
		},
		data(){
		    return {
				isScrollTop: false,
				isTips: false,
				isFirst: true, //是否弹出提示框
				evaluationList: [], //测评列表数据
				showIndex: -1,
				isToast: false,
				toastText: ''
		    }
		},
		created() {
			
		},
		mounted() {
			this.isFirst = (JSON.parse(localStorage.getItem("isFirst")));
			this.getEvaluation();
		},
        methods:{
			//显示全部
			openAll(index){
				this.showIndex = index;
			},
			//获取测试列表
			getEvaluation(){
				let that = this;
				this.axios
					.post(this.HOME + "/api/evaluation/evaluation-categories", {})
					.then(function(res) {
						let list = res.data.data;
						let newArray = [];
						list.forEach(item=>{
						  let obj = {
							id: item.id,
							expect_time: item.expect_time,
							factor_all: item.factor_all,
							description: item.description,
							summary: item.summary,
							usersCount: item.usersCount,
							cover: item.cover,
							title: item.title
						  }
						  newArray.push(obj);
						})
						that.evaluationList = newArray;
						console.log(that.evaluationList,'测评列表数据')
					});
			},
			//测试入口
			toEntrance(title,factor){
				if(factor == 1){
					localStorage.evaluationTitle = JSON.stringify(title);
					if(this.isFirst){
						this.isTips = !this.isTips;
					}else{
						this.$router.push('/evaluation/entrance');
					}
				}else{
					this.toastText = '亲，本测评尚未开通，请耐心等待',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
					return false;
				}
				
			},
			//下次不再提醒
			noTips(){
				this.isFirst = false;
				localStorage.isFirst = JSON.stringify(this.isFirst);
				this.$router.push('/evaluation/entrance');
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			}
        }
    }
</script>

<style scoped>
	
</style>