<template>
	<div class="content">
		<!-- 头部 -->
		<div :class="{'header detail-header':true,'scroll white':isScrollTop}">
			<div class="back white-back" @click="$router.go(-1)"><img src="../../assets/images/home/expert/back.png" alt=""></div>
			<div class="header-title">专家详情</div>
		</div>
		<!-- 轮播图 -->
		<div class="banner detail-banner">
			<div class="detail-photo">
				<span><img :src="host + expertDetail.avatar" ></span>
				<div class="detail-txt">
					<h1>{{expertDetail.name}}<span>{{expertDetail.tags_text}}</span></h1>
					<p>
						<span v-for="(item,index) in expertDetail.keywords_list" :key="index">{{item}}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="detail">
			<div class="expert-abstract">
				<h2>
					<img src="../../assets/images/home/expert/abstract-left.png">
					<span>咨询理念</span>
					<img src="../../assets/images/home/expert/abstract-right.png" >
				</h2>
				<p>{{expertDetail.slogan}}</p>
			</div>
			<div class="detail-content detail-section">
				<div class="expert-body">
					<h2>个人简介</h2>
					<span>
						<p>{{expertDetail.summary}}</p>
					</span>
				</div>
				<div class="expert-region">
					<h2>服务区域</h2>
					<p>
						<span>{{expertDetail.service_area_text}}</span>
						<span>尤其擅长{{expertDetail.service_area_main_text}}的志愿填报</span>
					</p>
				</div>
				<div class="expert-case" v-if="expertCase.length>0">
					<h2>咨询案例</h2>
					<div class="expert-case-list">
						<div class="expert-case-item" v-for="(item,index) in expertCase" :key="index">
							<div class="case-img"><img :src="host + item.cover" alt=""></div>
							<h3><span>案例{{index+1}}：</span>{{item.title}}</h3>
							<p v-if="item.result_remarks"><span>录取结果：</span><span>{{item.result_remarks}}</span></p>
							<p v-if="item.location_remarks"><span>专业定位：</span><span>{{item.location_remarks}}</span> </p>
							<p v-if="item.other_remarks"><span>兴趣类型：</span><span>{{item.other_remarks}}</span></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "expert-detail",
		data() {
			return {
				isScrollTop: false,
				expertDetail: [],
				expertCase: [],
				id: ''
			}
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			this.id = this.$route.query.id;
		},
		mounted() {
			this.getDetailData();
			this.getExpertCase();
		},
		computed: {
			
		},
		methods: {
			// 获取专家详情
			getDetailData() {
			  let that = this;
			  this.axios
			    .post(this.HOME + "/api/consumer/expert-info", {
			      id: that.id
			    })
			    .then(function (res) {
					let list = res.data.data;
					let newArray = [];
					let array = {
					  id: list.id,
					  name: list.name,
					  avatar: list.avatar,
					  tags_text: list.tags_text,
					  slogan: list.slogan,
					  summary: list.summary,
					  service_area_text: list.service_area_text,
					  im_group_link: list.im_group_link,
					  im_group_qr: list.im_group_qr,
					  service_area_main_text: list.service_area_main_text
					}
					newArray = array;
					that.expertDetail = newArray;
			    });
			},
			
			// 获取专家案例
			getExpertCase(){
				let that = this;
				this.axios
				  .post(this.HOME + "/api/expert/get-expert-case-list", {
						page: 1,
				        size: 100,
				        expert_id: that.id,
				        is_main: 1
				  })
				  .then(function (res) {
						let list = res.data.data.list;
						let newArray = [];
						list.forEach(item=>{
						  let obj = {
							cover:item.cover,
							title: item.title,
							result_remarks: item.result_remarks,
							location_remarks: item.location_remarks,
							other_remarks: item.other_remarks,
							account_remarks: item.account_remarks,
						  }
						  newArray.push(obj);
						})
						that.expertCase = newArray;
				  });
			},
			
			// 滚动显示头部
			eventScrollTop() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
			
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop)
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		}
	}
</script>

<style scoped>
	
</style>