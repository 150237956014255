<template>
	<div class="content bind-content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back" @click="$router.go(-1)">
				<img src="../../../assets/images/home/expert/back.png" alt="" />
			</div>
			<div class="header-title">意见与反馈</div>
		</div>
		<div class="info-bg pull-content">
			<div class="feedback-msg">
				<textarea v-model="content" placeholder="请输入您反馈的内容"></textarea>
			</div>
		</div>
		<div @click="btnFeedback()" class="bind-btn">提交</div>
		
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "feedback",
		data() {
			return {
				isScrollTop: false,
				content: "",
				isToast: false,
				toastText: ''
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			
		},
		mounted() {},
		computed: {},
		methods: {
			btnFeedback() {
				if (this.content == "") {
					this.toastText = '反馈内容不能为空',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
					return;
				}else if(this.content.length<=6){
					this.toastText = '反馈内容过少',
					this.isToast = true,
					setTimeout(()=> {
						this.isToast = false
					}, 1000)
					return;
				}else{
					this.axios
						.post(
							this.HOME + "/api/consumer/submit-feedback", {
								content: this.content
							},
						)
						.then((res)=> {
							console.log(res.data.msg,2525)
							this.toastText = res.data.msg,
							this.isToast = true,
							setTimeout(()=> {
								this.isToast = false
								this.$router.push('/ucenter');
							}, 1000)
						})
						.catch(function(err) {
							window.alert('提交失败')
						});
				}
				
			},
			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
</style>
