import { render, staticRenderFns } from "./texta.vue?vue&type=template&id=310077f5&scoped=true&"
import script from "./texta.vue?vue&type=script&lang=js&"
export * from "./texta.vue?vue&type=script&lang=js&"
import style0 from "./texta.vue?vue&type=style&index=0&id=310077f5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310077f5",
  null
  
)

export default component.exports