<template>
	<div class="content main-content">
		<!-- 轮播图 -->
		<div class="banner-wrap">
			<div class="swiper-container" ref="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<img :src="host + bannerSrc" />
					</div>
				</div>
				<div class="swiper-pagination" ref="swiper-pagination"></div>
			</div>
		</div>
		<!-- 领取咨询卡 -->
		<div class="index-receive" @click="goPage('/information')" v-if="consult_amount == 0 && !isExpire">
			<img src="../../assets/images/home/index/receive.jpg" />
			<span>完善信息，立即领取免费咨询卡(1小时)</span>
		</div>
		<!-- 专家 -->
		<div class="index-expert section">
			<div class="index-title">
				<h2>专家<em>团队</em></h2>
				<span @click="goPage('/expert')">查看更多</span>
			</div>
			<ul class="index-expert-list">
				<li v-for="(item, index) in expertData" :key="index" @click="toDetail(item.id)">
					<div class="expert-img">
						<img :src="host + item.avatar"/>
					</div>
					<div class="expert-name">
						{{item.name}}<span>{{item.work_time}}</span>
					</div>
					<div class="expert-honor">
						<span>{{item.home_keywords_list[0]}}</span>
						<span>{{item.home_keywords_list[1]}}</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="index-ypzy" v-if="adSrc" @click="toNewPage()">
			<img :src="host + adSrc">
		</div>
		<!-- 计划 -->
		<div class="index-plan">
			<ul class="index-plan-list">
				<li @click="goDetail('/texta')">
					<img src="../../assets/images/home/index/plan-01.png"/>
					<p>强基计划</p>
				</li>
				<li @click="goDetail('/textb')">
					<img src="../../assets/images/home/index/plan-02.png"/>
					<p>综合评价</p>
				</li>
				<li @click="goDetail('/textc')">
					<img src="../../assets/images/home/index/plan-03.png"/>
					<p>专项计划</p>
				</li>
			</ul>
		</div>
		<!-- 测评 -->
		<div class="index-evaluation section">
			<div class="index-title">
				<h2>专业<em>测评</em></h2>
				<span @click="goPage('/evaluation')">查看更多</span>
			</div>
			<ul class="index-evaluation-list">
				<li>
					<div class="evaluation-img">
						<img src="../../assets/images/home/index/evaluation-01.png"/>
					</div>
					<p>霍兰德职业兴趣测评</p>
					<div class="evaluation-btn" @click="toEvaluation()">进入测评</div>
				</li>
				<li>
					<div class="evaluation-img">
						<img src="../../assets/images/home/index/evaluation-02.png"/>
					</div>
					<p>MBIT职业性格测试</p>
					<div class="evaluation-btn" @click="toEvaluation()">进入测评</div>
				</li>
			</ul>
		</div>
		<!-- 有谱快看 -->
		<div class="index-live section">
			<div class="index-title">
				<h2>有谱<em>快看</em></h2>
				<span @click="goPage('/live')">查看更多</span>
			</div>
			<ul class="index-live-list">
				<li @click="goPage('/video02')">
					<img src="../../assets/images/home/index/live-02.jpg"/>
				</li>
				<li @click="goPage('/video03')">
					<img src="../../assets/images/home/index/live-03.jpg"/>
				</li>
			</ul>
		</div>
		<!-- 头条 -->
		<div class="index-headlines section">
			<div class="index-title">
				<h2>有谱<em>头条</em></h2>
				<span @click="goPage('/headlines')">查看更多</span>
			</div>
			<ul class="index-headlines-list">
				<li @click="goDetail('/text01')">
					<p>如何让孩子找到有幸福感的专业方向和职业目标？</p>
					<!-- <span>{{ item.time }}</span> -->
				</li>
				<li @click="goDetail('/text02')">
					<p>生涯规划师的服务伦理，以及处理与客户价值判断不一致的方法探讨</p>
					<!-- <span>{{ item.time }}</span> -->
				</li>
				<li @click="goDetail('/text03')">
					<p>升学规划师能力的“冰山结构”</p>
					<!-- <span>{{ item.time }}</span> -->
				</li>
				<li @click="goDetail('/text04')">
					<p>双减政策，减“教”不减“学”；生涯规划的目标是点燃学生求学热情，真正体现教育情怀</p>
					<!-- <span>{{ item.time }}</span> -->
				</li>
				<li @click="goDetail('/text06')">
					<p>数字化生涯规划师：获权威认证，服务钉钉千万考生与家长</p>
					<!-- <span>{{ item.time }}</span> -->
				</li>
			</ul>
		</div>
		<tabbar :current="1"></tabbar>
	</div>
</template>

<script>
	import tabbar from "../../components/tabbar.vue";
	import "../../../public/js/count.js";
	export default {
		name: "index",
		components: {
			tabbar,
		},
		data() {
			return {
				consult_amount: 0, //是否完善资料
				bannerSrc: "", //首页banner图
				adSrc: "", //首页广告图
				adUrl: "", //广告跳转地址
				expertData: [], //专家列表
				video: null,
				isPaused: true,
				isExpire:false, //是否过有效期
				ddEntrance:'', //钉钉进入
			};
		},
		created() {
			if(this.$route.query.token){
				let token = this.$route.query.token;
				localStorage.token = JSON.stringify(token);
				localStorage.ddEntrance = JSON.stringify('yes');
				this.getUserData();
			}else{
				// 获取用户类型
				this.consult_amount = (JSON.parse(localStorage.getItem("centerUserData"))).consult_amount;
			}
			
			if(JSON.parse(localStorage.getItem("ddEntrance"))){
				this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			}
			
			// 获取当前时间
			let date2 = new Date();
			let dateNumber = '';
			dateNumber = dateNumber.concat(date2.getFullYear(),date2.getMonth()+1,date2.getDate());
			if(parseInt(dateNumber) > 2022614){
				this.isExpire = true;
			}
			
		},
		mounted() {
			this.getBanner();
			this.getHot();
			this.getExpertData();
		},
		computed: {},
		methods: {
			//查询用户信息
			getUserData() {
				let that = this;
				this.axios.post(this.HOME + "/api/user/center-info").then(function(res) {
					console.log(res.data.data)
					// 获取用户类型
					that.consult_amount = res.data.data.consult_amount;
					localStorage.centerUserData = JSON.stringify(res.data.data);
				});
			},
			// 跳转到详情页
			toDetail(id) {
				this.$router.push({
					path: '/expert/detail',
					name: 'expert-detail',
					query: {
						id: id
					},
				})
			},
			// 测试跳转
			toEvaluation() {
				this.$router.push({
					path: "/evaluation",
				});
			},
			
			// 获取banner
			getBanner() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/dictionary/get-slide-list", {
						position: "h5_home_banner",
					})
					.then(function(res) {
						console.log(res,'首页banner')
						that.bannerSrc = res.data.data[0].src;
					});
			},
			
			// 获取首页广告
			getHot() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/dictionary/get-slide-list", {
						position: "h5_home_ad",
					})
					.then(function(res) {
						console.log(res,'首页广告')
						that.adSrc = res.data.data[0].src;
						that.adUrl = res.data.data[0].url;
					});
			},
			
			// 获取专家列表
			getExpertData() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/consumer/expert-list", {
						page: "1",
						size: "3",
						is_home: 1,
					})
					.then(function(res) {
						let list = res.data.data.list;
						console.log(list,123)
						let newArray = [];
						list.forEach(item=>{
						  let obj = {
							id: item.id,
							work_time:item.work_time,
							avatar: item.avatar,
							name: item.name,
							keywords_list: item.keywords_list,
							home_keywords_list: item.home_keywords_list
						  }
						  newArray.push(obj);
						})
						that.expertData = newArray;
					});
			},
			
			// 跳转页面
			goPage(path){
				this.$router.push(path);
			},
			goDetail(path){
				this.$router.push(path);
			},
			toNewPage(){
				let url = this.adUrl;
				if(this.adUrl == '' || this.adUrl == '#'){
					return false;
				}else{
					if(this.ddEntrance == 'yes'){
						// 网页向小程序 postMessage 消息
						dd.postMessage({name:'newsPage',url:url});
					}else{
						window.open(url);
					}
				}
			}
		},
	};
</script>

<style scoped>
	
</style>
