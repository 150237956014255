<template>
  <div class="content">
    <!-- 头部 -->
    <div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
      <div class="back" @click="$router.go(-1)">
        <img src="../../assets/images/home/expert/back.png" alt="" />
      </div>
      <div class="header-title">列表预览</div>
    </div>
    <!-- 咨询师列表 -->
    <div class="expert-list-all section">
      <ul class="expert-list">
        <li>
          <div class="expert-list-con">
            <div class="expert-list-img">
              <img :src="this.host+userData.avatar" alt="" />
            </div>
            <div class="expert-list-txt">
              <div class="title">
                <h2>{{ userData.name }}</h2>
                <span v-if="userData.title">{{ userData.title }}</span>
              </div>
              <div class="integral">
                <span>公益积分：{{ userData.score }}</span>
                <span>好评度：{{ userData.comment_score }}</span>
              </div>
              <div class="honor">
                <span
                  v-for="(itemk, indexk) in userData.keywords_list"
                  :key="indexk"
                  >{{ itemk }}</span
                >
              </div>
              <div class="slogan">{{ userData.summary }}</div>
            </div>
          </div>
          <div class="expert-list-btns">
            <div :class="{ btn: true }">向TA提问</div>
            <div class="btn">查看详情</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
	export default {
		name: "edit-list",
		data() {
			return {
				isScrollTop: false,
				isReceive: 1, //是否领取咨询机会
				current: 0,
				isConfirm: false,
				userData: {},
				expertData: [{
						name: "赵晓",
						title: "金牌咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
					{
						name: "赵晓",
						title: "专家咨询师",
						imgsrc: "../../assets/images/home/index/expert.png",
						integral: "985",
						score: "8.5",
						honor: [{
								title: "中科院心理科"
							},
							{
								title: "硕士"
							},
							{
								title: "从业5年"
							},
							{
								title: "熟悉高考"
							},
						],
						slogan: "一所好的大学，是年轻人的家，是他 们度过人生最好时光的地方！",
					},
				],
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			this.checkExperInfo();
		},
		methods: {
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
			goPage(path) {
				this.$router.push(path);
			},
			checkExperInfo() {
				let that = this;
				this.axios
					.post(this.HOME + "/api/user/center-info", {})
					.then(function(res) {
						console.log(res.data.data.expert);
						that.userData = res.data.data.expert;
						console.log(that.userData);
					});
			},
		},

		destroyed() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive进入时触发
		activated() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
		},
		//keep-alive离开时触发
		deactivated() {
			window.removeEventListener("scroll", this.eventScrollTop);
		},
	};
</script>

<style scoped>
	.expert-list{
		margin-top: 1.2rem;
	}
</style>
