<template>
	<div class="content bind-content">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
			<div class="back" @click="$router.go(-1)">
				<img src="../../../assets/images/home/expert/back.png" alt="" />
			</div>
			<div class="header-title">个人信息</div>
		</div>
		<div class="info-bg pull-content">
			<ul class="phone-info">
				<li>
					<span>头像</span>
					<p class="ps">
						<input class="inputB" @change="imgIny($event)" type="file" />
						<img v-if="myAvatar" :src="host + myAvatar">
						<img v-else-if="userDataAll.avatar" :src="host + userDataAll.avatar">
						<img v-else src="../../../assets/images/user/photo.png" />
					</p>
				</li>
				<li>
					<span>昵称</span><input type="text" v-model="nickName" value="" placeholder="请输入昵称" />
				</li>
			</ul>
		</div>
		<div class="bind-btn" @click="setInfo()">提交</div>
		<!-- 提示框 -->
		<div class="addr-select-bg" v-show="isToast"></div>
		<div class="toast" v-show="isToast">
			<p>{{toastText}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "info",
		data() {
			return {
				isScrollTop: false,
				myAvatar: '',
				nickName:'',
				userDataAll:[],
				isToast:false,
				toastText:''
			};
		},
		created() {
			this.isScroll = true;
			window.addEventListener("scroll", this.eventScrollTop);
			this.userDataAll = (JSON.parse(localStorage.getItem("centerUserData")));
			this.nickName = this.userDataAll.nick_name;
		},
		mounted() {},
		computed: {},
		methods: {
			imgIny(e) {
				let that = this;
				const files = e.target.files;
				console.log(files[0]);
				console.log({
					image: files[0]
				});
				var fromdata = new FormData();
				fromdata.append("image", files[0]);
				this.axios
					.post(this.HOME + "/api/file/upload/image", fromdata, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(function(res) {
						that.myAvatar = res.data.data.src;
						console.log(that.myAvatar, 123);
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			
			// 提交
			setInfo(){
				let that = this;
				this.axios
					.post(this.HOME + "/api/user/edit-base-info", {
						nick_name: that.nickName,
						avatar: that.myAvatar
					})
					.then(function(res) {
						that.getUserData();
						that.toastText = res.data.msg,
						that.isToast = true,
						setTimeout(()=> {
							that.isToast = false;
							that.$router.push('/ucenter');
						}, 1000)
					});
			},
			
			// 获取用户信息
			getUserData() {
			  let that = this;
			  this.axios.post(this.HOME + "/api/user/center-info").then(function (res) {
			    that.userData = res.data.data;
				localStorage.centerUserData = JSON.stringify(res.data.data);
			  });
			},


			// 滚动改变样式
			eventScrollTop() {
				let scrollTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 5) {
					if (this.isScroll) {
						this.isScroll = false;
						this.isScrollTop = true;
					}
				} else {
					if (!this.isScroll) {
						this.isScroll = true;
						this.isScrollTop = false;
					}
				}
			},
		},
	};
</script>

<style scoped>
	.inputB {
		width: 1.1rem;
		height: 1.1rem;
		position: absolute;
		left: 0px;
		z-index: 5;
		top: 0px;
		opacity: 0;
	}

	.ps {
		position: relative;
	}
</style>
