<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">强基计划</div>
		</div>
		<div class="headlines-txt plan-txt">
			<h2>强基计划面试培训课表</h2>
			<p>紧跟教育部战略步伐，钉钉阿里云教育生涯规划师面试团队深度了解强基计划的实施方案，结合当代高中生的学习模式和学习特点，量身定制“强基计划”面试培训方案，因材施教，让考生在赢得高考成绩胜利的基础上，如虎添翼，进一步取得“强基计划”面试的胜利。</p>
			<img src="../../../assets/images/home/plan/101.png" alt="">
			<p>针对不同高校的强基计划面试方式、报考学科专业特点和办学特色，精心设计面试培训内容，确保考生灵活应对面试，钉钉阿里云教育生涯规划师启动全国36所高校强基计划面试专项辅导课程。</p>
			<h2>强基面试授课内容</h2>
			<p>·强基计划考情介绍，考试题型划分和考试特点等全面掌握，超细化阶梯讲练强基计划面试流程。</p>
			<p>·面试礼仪详解，从着装、考试礼仪等外在形式方面，塑造并体现考生的精神面貌。</p>
			<p>·对强基计划面试有可能涉及到的个人面谈和小组面试等常见面试形式，全面撒网、重点突击，讲解面试规则和高分技巧。</p>
			<p>·全方位、多角度讲解面试题型和题目，提高考生逻辑思维、分析辩证、自我认知、发散创新思维等综合能力。</p>
			<p>·结合当前新闻时事热点和资讯，提前组织面试模拟演练，复原真实面试考场，熟悉面试细节。</p>
			<p>·名师预测强基计划面试真题，根据历年内部研发教学成果，结合高校面试特点进行相关预测，高质量原创题目与你共享。</p>
			<h2>强基面试授课方式</h2>
			<p>10人左右小班课，引导式讲解，互动式教学，保证授课质量。</p>
			<p>VIP课程一对一，科学测评，教学细化到每个考核知识点，各个击破。</p>
			<p>线上&线下双螺旋培训方式，互补教学，任你选择。</p>
			<h2>强基面试授课师资</h2>
			<p><strong>朱老师</strong></p>
			<p>知名大学副教授，中国翻译协会会员。11年高校自主招生面试考官及辅导经验，在高校面试方面颇有建树，独创面试教学法，对于结构化面试/半结构化面试/无领导小组讨论等研究颇深，擅长激发学生学习主动性，注重互动，强调面试答题思维训练。</p>
			
			<p><strong>夏老师</strong></p>
			<p>哲学博士，省属高校副教授，归国留学专家。先后就读于浙江大学、上海外国语大学等，曾任职于浙江省人社厅职业技能培训讲师，浙江省内各中小学教师培训项目负责人，拥有丰富的高校综合评价、强基计划等面试辅导经验，对各大高校结构化面试、半结构化面试、无领导小组讨论等多种面试形式非常熟悉，能够高效指导学员掌握面试技能，更好更快速地规避面试差错。</p>
			
			<p><strong>胡老师</strong></p>
			<p>浙江大学和华东师范大学硕士双学位，教育科学研究理论扎实，具备教育学和心理学专业知识，能够结合学员特质因材施教，教学实践能力强。多年大型培训机构面试辅导经验，授课解惑清晰严谨，擅长根据面试热点深度挖掘面试技巧，帮助学员突破临场压力，轻松上阵。</p>
			
			<p><strong>张老师</strong></p>
			<p>北师大语言学应用硕士，优朗高校面试课程首席讲师，著名高校副教授。中国先秦史学会国学双语研究会理事，世界汉语大会国外教育部长随行翻译，华尔街某金融公司CEO访谈节目现场口译官。13年从业经验，9年专注高校面试，具备扎实的理论基础和实战经验。</p>
			<h2>单击以下二维码，弹出后识别即可联系规划师</h2>
			<div class="ewm" @click="downImg()" v-if="ddEntrance == 'yes'">
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" alt="">
			</div>
			<div class="ewm" v-else>
				<img src="http://aspevel.scdxtc.cn/img/dysxewm.jpg" v-image-preview alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "texta",
		data() {
			return {
				ddEntrance:'', //钉钉进入
			}
		},
		beforeCreate() {
		},
		created() {
			if(JSON.parse(localStorage.getItem("ddEntrance"))){
				this.ddEntrance = JSON.parse(localStorage.getItem("ddEntrance"));
			}
		},
		methods: {
			// 下载二维码事件
			downImg() {
				// 网页向小程序 postMessage 消息
				dd.postMessage({name:'downImg',url: 'http://aspevel.scdxtc.cn/img/dysxewm.jpg'});
			},
		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.plan-txt{
		padding-top: 1.3rem;
	}
	.headlines-txt>h2{
		font-size: .34rem;
		line-height: 1.5;
		text-align: center;
		margin-top: .3rem;
	}
	.plan-txt>h2:first-of-type{
		margin-top: 0;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
	.ewm{
		width: 100%;
	}
	.ewm img{
		width: 100%;
	}
</style>
