<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">强基计划、综合评价报名材料如何准备</h1>
		<div class="headlines-txt">
			<p>强基计划和综合评价是新高考改革多元升学通道的重要组成部分，据称，2021年强基计划的报考人数达到了187万人，“数字化生涯规划师”项目关注规划师在多元升学通道上的服务能力。关注“数字化生涯规划师项目”认证课程方法：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”。</p>
			<img src="../../../assets/images/headlines/501.png" alt="">
			<p>每年3月份，综合评价与强基计划会陆续启动报名，关注这一方向的考生需要提前准备报名材料，要准备哪些材料、该如何准备呢？</p>
			<p>为什么要提前做好准备？</p>
			<p>按照往年经验，强基计划、综合评价报名时间通常在10-20天左右。在有限的时间里，要完成政策研究、院校定位、材料准备盖章上传等全部报名工作，非常紧张。而且，报名材料是否符合要求直接影响初审结果！</p>
			<p>往年，缺少成绩单、证书找不到、材料不符合要求的情况非常常见。因此，我们建议家长，提前进行材料准备工作，待报名时，再根据高校简章要求微调即可。</p>
			<p>需要准备哪些材料？</p>
			<p>大多数高校强基计划不需要单独提交报名材料，但仍有电子科大、重大、中海洋等部分高校仍然需要上传个人陈述等报名材料；北大、清华需要平时成绩、个人陈述、获奖证书等全套材料。即使报名时不需要材料的高校，也需要在报名结束后准备综合素质评价表。</p>
			<p>结合往年报名情况来看，各校强基、综评报名材料通常包括以下几类：</p>
			<p>1、报名申请表；</p>
			<p>2、自荐信/个人陈述；</p>
			<p>3、高中阶段文化课成绩；</p>
			<p>4、高中学业水平测试成绩；</p>
			<p>5、推荐信；
			<p>6、高中阶段获奖证书及证明材料；</p>
			<p>7、其他可证明材料；
			<p>8、高校特殊要求提供的材料。</p>
			<p>以下对各种材料所需之准备作详细说明。</p>
			<img src="../../../assets/images/headlines/502.png" alt="">
			<p>1、报名申请表的准备</p>
			<p>报名申请表包括考生个人情况、联系方式、中学就读信息、成绩情况、获奖情况、参与活动情况及志愿选择等，是考生个人情况的综合展示。</p>
			<p>申请表不用考生单独准备，只需按照报名系统提示填写完成各项信息后系统自动生成。下载打印后由本人签字并由中学审核、校长签字并在中学网站和班级详尽公示。</p>
			<p>操作流程与注意事项：</p>
			<p>申请表的操作可以概括为：网上填写→提交→下载打印→签字盖章→扫描或拍照上传。期间一定要注意3步：（1）考生本人签字；（2）中学负责人或中学校长签字；（3）加盖中学公章。</p>
			<p>2、自荐信/个人陈述</p>
			<p>通过往年各高校综合评价简章来看，大部分学校都是需要提供自荐信/个人陈述的，字数一般在800-1000字之间，个别高校还会要求中英文双语或者亲笔手写。</p>
			<p>撰写时一定要围绕自身情况和招生简章要求，主要内容是关于自身专业相关优点的介绍，关于报考相关院校和相关专业的理由，以及大学和职业规划等。</p>
			<p>注意事项：</p>
			<p>考生们准备的自荐信/个人陈述千万不要自夸自大、浮夸，一定不要套用模板，字数不宜过短或过长，自荐信/个人陈述是高校老师对考生的第一印象，一定要认真对待。</p>
			<p>3、高中阶段文化课成绩</p>
			<p>高中阶段文化课成绩是指考生高一到高三不同各阶段的期末考试成绩。各院校对成绩的要求会有所不同，如北外要求高三第一学期期末成绩在同科类排名前10%。</p>
			<p>报名时，考生需按照要求在综合评价报名系统中如实填写。文化课成绩单可以找考生中学班主任及教务处进行查询。</p>
			<p>注意事项：</p>
			<p>不少学校在综合评价招生简章中并没有提到需要提交成绩，其实几乎全部高校都会在报名材料中要求提供高中阶段期末和模考的成绩，这也会作为初审的重要考核标准。</p>
			<p>4、高中学业水平测试成绩</p>
			<p>高中学业水平测试成绩同高中阶段文化课成绩一样，在报名系统有对应的填写模块。学业水平测试成绩根据本省情况填写A、B、C、D、E或合格、不合格，请勿直接填写分数。没有的科目可以填无 。</p>
			<p>注意事项：</p>
			<p>学业水平考试成绩需要填写考生等级和对应最高等级。在综合评价中，学业水平考试成绩不仅是初审审核依据，还在最终录取时占据约5%至15%的比重。</p>
			<p>5、推荐信</p>
			<p>推荐信并不是每个高校都需要提供的，部分高校会有要求，一般是考生的班主任、任课老师、课外活动指导老师等叙述学生特点及推荐的理由，一般要求在800字之内。</p>
			<p>注意事项：</p>
			<p>建议考生尽量找和报考方向同一方向的专家或者对考生比较了解的老师来准备推荐信。</p>
			<p>6、高中阶段获奖证书</p>
			<p>高中阶段参加竞赛所获得的奖项证书等可以证明学科特长及创新潜质的材料。如：五大学科竞赛奖项、科创类竞赛奖项等。（注意：获奖证书复印件要求盖学校公章，原件要求直接拍照上传，考生报名前还需仔细阅读简章）。</p>
			<p>注意事项：</p>
			<p>并不是所有高校都强制考生提交获奖证书，但有奖项的考生在初审考核中会有一定的优势。</p>
			<p>7、其他可证明材料</p>
			<p>其他可证明材料包括但不限于以下材料，如：社会实践活动，省级或市级三好学生、优秀班干部等可以反映学生综合素质发展情况的材料。</p>
			<p>8、高校特殊要求提供的材料</p>
			<p>如诚信承诺书原件（网上报名系统自动生成，打印后须本人签名）；《健康状况信息登记表》等。</p>
			<p>“数字化生涯规划师”项目关注规划师在多元升学通道上的服务能力，关注“数字化生涯规划师项目”认证课程方法：钉钉—进入小程序界面（方法与微信相同）—搜索“新职业在线学习平台”—在新职业在线学习平台搜索“数字化生涯规划师”。</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text05",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
