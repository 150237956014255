<template>
	<div class="content headlines">
		<!-- 头部 -->
		<div :class="{ header: true, 'scroll white': isScrollTop, white: true }">
		  <div class="back" @click="$router.go(-1)">
		    <img src="../../../assets/images/home/expert/back.png" alt="" />
		  </div>
		  <div class="header-title">有谱头条</div>
		</div>
		
		<h1 class="headlines-title">升学规划师能力的“冰山结构”</h1>
		<div class="headlines-txt">
			<p>今社会，职业和专业选择日趋多样性；更重要的是，社会变化的加速度，使得不同职业和专业未来前景的消长迅速。学生在升学的时候，特别是在高考志愿填报的时节，特别需要专职的升学规划师来提供专业的意见。</p>
			<img src="../../../assets/images/headlines/301.png" alt="">
			<p>而升学规划师自身，显然需要具备应有的能力结构，才能确保每一个升学规划、志愿填报等服务达到实有的品质标准。</p>
			<p>有谱生涯从若干维度，不同剖面看待升学规划师（志愿填报方向）的能力结构；其中一个维度我们称之为“冰山结构”。升学规划师能力的“冰山结构”是从“显性能力”和“隐性能力”，也即被咨询者可直接感知的能力与较难直接感知的能力，两个维度来解构升学规划师的能力结构。</p>
			<p>升学规划师能力的“冰山结构”分为两个大部分，共7个能力维度。分别是</p>
			<p>	一、显性能力：</p>
			<p>	1、有利学生长期发展的专业定位能力；</p>
			<p>	2、制作科学志愿方案的能力，标准是低录取线差，也即通常所说的“不浪费每一分”的志愿填报服务目标；</p>
			<p>	3、耐心细致的服务与沟通，让用户在专业度外，还感知到温暖，并需有效防止在志愿填报过程中出现可能会产生严重后果的细小错误；</p>
			<p>	二、隐性能力：</p>
			<p>	4、理解或帮助考生理解自己的个人特质所需要的心理学知识；</p>
			<p>	5、认清社会科技经济发展趋势的综合知识；</p>
			<p>	6、理解教育改革与高招规划的能力；</p>
			<p>	7、领域资讯与服务案例积累所带来的经验与能力。</p>
			<p>其中第4、5项能力，是支持第1项显性能力的基础性能力；第6、7项能力，是支持第2项显性能力的基础性能力。</p>
			<p>升学规划师能力的“冰山结构”，只是我们解构升学规划师能力结构的一种视角，以后有机会和大家陆续分享其它的视角。</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "text03",
		data() {
			return {
				
			}
		},
		beforeCreate() {
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.headlines-title{
		padding: 1.3rem .25rem 0;
		font-size: .36rem;
		line-height: 1.5;
	}
	.headlines-txt{
		padding: .2rem .25rem;
		overflow: hidden;
	}
	.headlines-txt>p{
		font-size: .3rem;
		line-height: 1.6;
		text-align: justify;
		color: #444;
		text-indent: 2em;
		margin: .12rem 0;
	}
	.headlines-txt img{
		max-width: 100%;
		margin: .15rem auto;
	}
</style>
